import { useDebounce } from './useDebounce';
import { useQuery } from 'react-query';
import { CompanySelectType } from '../types/main';
import axios from 'axios';

type Pagination = {
  total: number;
};

type CompanyList = {
  pagination: Pagination;
  companies: CompanySelectType[];
};

type CompaniesFetch = {
  currentPage: number;
  debouncedSearchTerm: string;
};

const fetchCompanies = (currentPage: number, searchTerm: string, subscriptionType: string): Promise<CompanyList> => {
  const queryParams = { page: currentPage.toString(), filter: searchTerm, subscriptionType: subscriptionType };

  const queryString = new URLSearchParams(queryParams).toString();

  // Use axios instead of fetch
  return axios.get(`${process.env.REACT_APP_API_URL}/companies-for-admin?${queryString}`).then((response) => {
    return response.data;
  });
};

export const useCompanies = (currentPage: number, searchTerm: string, subscriptionType: string) => {
  const debouncedSearchTerm: string = useDebounce<string>(searchTerm, 500);

  const { isLoading, data, refetch } = useQuery({
    queryKey: ['companies', currentPage, debouncedSearchTerm, subscriptionType],
    queryFn: () => fetchCompanies(currentPage, debouncedSearchTerm, subscriptionType),
    onError: () => {
      // TODO show notifications
    },
  });
  const companies = data?.companies || [];
  const count = data?.pagination?.total || 0;
  return {
    companies,
    count,
    isLoading,
    refetch,
  };
};
