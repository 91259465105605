import { max, range, scaleBand, scaleLinear, select, Selection as D3Selection } from 'd3';
import { useEffect, useRef } from 'react';
import { COLOR } from '../constants';
import { Tooltip } from './tooltip';

type D3SvgElement = D3Selection<SVGSVGElement, unknown, null, undefined>;

function updateChart(svg: D3SvgElement, data: { name: string; value: number }[], width: number, colors: string[]) {
  let padding = 0.5;
  let ySize = 42;
  let margin = {
    top: 15,
    right: 0,
    bottom: 15,
    left: 0,
  };

  let x = scaleLinear()
    .domain([0, max(data, (d) => d.value) || 0])
    .range([margin.left, width - margin.right]);
  let y = scaleBand<number>()
    .domain(range(data.length))
    .rangeRound([margin.top, margin.top + ySize * data.length])
    .padding(padding);

  svg
    .select('#chart')
    .attr('fill', COLOR.purple1)
    .selectAll('circle')
    .data(data)
    .join(
      (enter) =>
        enter
          .append('circle')
          .attr('cx', "30%")
          .attr('cy', (d, i) => (y(i) || 0) + y.bandwidth() / 2)
          .attr('r', y.bandwidth() / 5)
          .attr('fill', (d, i) => colors[i]),
      (update) => update.attr('cx', "30%").attr('cy', (d, i) => (y(i) || 0) + y.bandwidth() / 2),
      (exit) => exit.remove()
    );

  svg
    .select('#chart')
    .attr('fill', COLOR.purple1)
    .selectAll('.label')
    .data(data)
    .join(
      (enter) =>
        enter
          .append('text')
          .classed('label', true)
          .attr('text-anchor', 'start')
          .attr('fill', COLOR.black1)
          .attr('font-size', '18')
          .attr('font-wight', '500')
          .attr('x', '40%')
          .attr('y', (d, i) => (y(i) || 0) + y.bandwidth() * 0.5 + 5)
          .text((d, i) => d.name),
      (update) =>
        update
          .attr('x', '40%')
          .attr('y', (d, i) => (y(i) || 0) + y.bandwidth() * 0.5 + 5)
          .text((d, i) => d.name),
      (exit) => exit.remove()
    );

  svg
    .select('#chart')
    .attr('fill', COLOR.purple1)
    .selectAll('.count')
    .data(data)
    .join(
      (enter) =>
        enter
          .append('text')
          .classed('count', true)
          .attr('text-anchor', 'end')
          .attr('fill', COLOR.black1)
          .attr('font-size', '18')
          .attr('font-wight', '500')
          .attr('x', width - margin.right)
          .attr('y', (d, i) => (y(i) || 0) + y.bandwidth() * 0.5 + 5),
          // .text((d, i) => `${d.value} Clicks`),
      (update) =>
        update
          .attr('x', width - margin.right)
          .attr('y', (d, i) => (y(i) || 0) + y.bandwidth() * 0.5 + 5),
          // .text((d, i) => `${d.value} Clicks`),
      (exit) => exit.remove()
    );
}

export function ListChart(props: {
  header: string;
  data: { categories: string[]; values: number[]; percentages: number[] };
  tooltip: string;
  width: number;
  height: number;
  colors: string[];
}) {
  let svgRef = useRef<SVGSVGElement>();

  useEffect(() => {
    if (svgRef.current === undefined) {
      return;
    }
    let data = props.data.categories.map((name, i) => ({
      name: name,
      value: props.data.values[i],
    }));
    const svg = select(svgRef.current);
    updateChart(svg, data, props.width, props.colors);
  }, [props.data, props.width, props.colors]);

  return (
    <div className="d-flex align-items-center flex-grow-1">
      <div className="w-100">
        <svg viewBox={`0 0 ${props.width} ${props.height}`} ref={(ref: SVGSVGElement) => (svgRef.current = ref)}>
          {/*<Tooltip*/}
          {/*  trigger={*/}
          {/*    <text x="30px" y="30px" fontWeight="500" fontSize="18px" cursor="help">*/}
          {/*      {props.header}*/}
          {/*    </text>*/}
          {/*  }*/}
          {/*>*/}
          {/*  {props.tooltip}*/}
          {/*</Tooltip>*/}
          <g id="chart"></g>
        </svg>
      </div>
    </div>
  );
}
