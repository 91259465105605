// @ts-nocheck
import {FC} from 'react';
import {toAbsoluteUrl} from "../../_metronic/helpers";

const BubbleImage: FC = () => {

    const path = window.location.pathname
    if (path.includes("/auth") || path === "/") {
        return null
    }

    return (
        <img
            src={toAbsoluteUrl('/media/chat/chat-pop-up.png')}
            className="bubble"
            // style={{position: "fixed", zIndex: "99", width: "50px", height: "50px", right: "30px", bottom: "30px", cursor: "pointer", transition: "transform  .2s", }}
            onClick={() => LiveChatWidget.call('maximize')}
            alt="chat-icon"
        />
    );
};

export default BubbleImage;