import React, { ReactNode, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import HypnosisLoader from '../HypnosisLoader';
import './upgrade.scss';
import YoutubeModal from '../Modals/YoutubeModal';

type UpgradeBlockProps = {
  showSpinner?: ReactNode | boolean;
  icon?: ReactNode;
  text?: string;
};
const UpgradeBlock = (props: UpgradeBlockProps) => {
  const { showSpinner, icon, text } = props;
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

  return (
    <>
      <div className="upgrade-block position-relative d-flex justify-content-center align-items-center">
        <div className="d-flex flex-column justify-content-center align-items-center" style={{ maxWidth: '270px' }}>
          {icon}

          {text && <p className="my-6 fs-h4 font-secondary font-normal text-center">{text}</p>}

          <div className="d-flex justify-content-center align-content-center">
            <Link to="/settings/plan">
              <Button size="sm" variant="dark" className="mx-2">
                Upgrade
              </Button>
            </Link>
            <Button
              size="sm"
              variant="outline-dark"
              className="btn-outline border-gray-900 mx-2"
              onClick={() => setModalIsOpen(true)}
            >
              See Video
            </Button>
          </div>
        </div>
        {typeof showSpinner === 'boolean' && showSpinner && (
          <div className="position-absolute top-0 bottom-0 start-0 end-0 bg-dark bg-opacity-25 z-2">
            <HypnosisLoader size={100} style={{ height: '80vh' }} />
          </div>
        )}
        {showSpinner}
      </div>
      <YoutubeModal setModalIsOpen={setModalIsOpen} show={modalIsOpen} />
    </>
  );
};

export default UpgradeBlock;
