export const USER_ROLES = {
    superadmin: 'superadmin',
    editor: 'editor'
}

export const checkBrowserVersion = (version: string, major: number, minor: number) => {
    // Safari '16.5' support
    const MAJOR  = version?.split(".")?.[0] || 0;
    const MINOR  = version?.split(".")?.[1] || 0;

    if (+MAJOR < major) {
        return false;
    } else if (+MINOR < minor) {
        return false;
    }
    return true
}