// @ts-nocheck
import { addDays, addMonths } from 'date-fns';
import React, { FC, useContext, useEffect, useState } from 'react';
import { AnalyticsData, APIAnalyticsData, initAnalyticsData, initAPIAnalyticsData, Range } from '../api/interfaces';
import { COLOR } from '../constants';
import { CalendarWidget } from './CalendarWidget';
import './dashboard.scss';
import { ListChart } from './listChart';
import { PieChart } from './pieChart';
import { useCompany } from 'hooks/useCompany';
import { FileContext } from 'app/modules/accounts/Filecontext';
import { Col, Row } from 'react-bootstrap';
import axios from 'axios';
import { GET_ANALYTICS_DATA } from 'app/modules/auth/core/_requests';
import { SelectCompanyModal } from 'app/components/Modals/SelectCompanyModal';
import { useAuth } from 'app/modules/auth';
import AreaChart from './AreaChart';
import ChartBlock from './ChartBlock';
import RadialChart from './RadialChart';
import BarChart from './BarChart';
import HypnosisLoader from '../../../components/HypnosisLoader';
import {
  fillDateRange,
  largeHeight,
  largeWidth,
  listHeight,
  median,
  newAbortSignal,
  smallWidth,
  timeFormat
} from './utils';
import UpgradeBlock from '../../../components/UpgradeBlock';
import { ToastContainer, toast } from 'react-toastify';
import Map from "../../../modules/accounts/components/Location/Map/Map";
import { postcodesCoord } from "./postcodesCoord";

type DashboardProps = {};

export const Dashboard: FC<DashboardProps> = () => {
  const { selectedCompany } = useContext(FileContext);
  const companyId = selectedCompany || localStorage.getItem('companyId');
  const { company, isLoading, refetch, isFetching } = useCompany(companyId as number);
  const [selectModalIsOpen, setSelectModalIsOpen] = useState<boolean>(false);
  const { currentUser } = useAuth();
  const isSuperAdmin = currentUser?.isSuperAdmin;
  const [loadingData, setLoadingData] = useState<boolean>(false);

  const [postcode, setPostcode] = useState<string>("");
  const [location, setLocation] = useState({
    latitude: '', longitude: ''
  });

  let today = addDays(new Date(), -1);
  const [dateRange, setDateRange] = useState<Range[]>([
    {
      startDate: addMonths(today, -12),
      endDate: today,
      key: 'selection',
    },
  ]);

  const [data, setData] = useState<AnalyticsData>(initAnalyticsData);
  const [fullData, setFullData] = useState<APIAnalyticsData>(initAPIAnalyticsData);

  useEffect(() => {
    if (postcode) {
      const currentCoordinate = postcodesCoord.find(({pc}) => pc === postcode.toString());
      if (currentCoordinate?.lat && currentCoordinate?.lng) {
        setLocation({
          latitude: currentCoordinate.lat,
          longitude: currentCoordinate.lng
        })
      } else
      if (company?.latitude) {
        setLocation({
          latitude: company?.latitude,
          longitude: company?.longitude
        })
      }
    }

  }, [company, postcode])

  useEffect(() => {
    if (data?.topPostcodes?.length) {
        setPostcode(data.topPostcodes[0]?.[0])
    } else if (company?.postcode) {
      setPostcode(company.postcode)
    }
  }, [company, data])

  const latCoord = location?.latitude
      ? location?.latitude
      : company?.latitude;
  const lngCoord = location?.longitude
      ? location?.longitude
      : company?.longitude;

  const showSpinner = isLoading || (!isLoading && !fullData.name && company?.analyticsId) || loadingData;

  useEffect(() => {
    if (company?.analyticsId) {
      let fetchCount = 0;
      const fetchData = async () => {
        setLoadingData(true);
        fetchCount = ++fetchCount;
        await axios
          .get(GET_ANALYTICS_DATA, {
            signal: newAbortSignal(20000), //Aborts request
            params: {
              uuid: company?.analyticsId,
              startDate: dateRange[0].startDate,
              endDate: dateRange[0].endDate,
            },
          })
          .then((response) => {
            if (response?.data) {
              setFullData(response.data);
              if (response?.data?.analytics && Object.keys(response?.data?.analytics).length) { // check if analytics isn't empty object
                if (response.data.analytics["API response"] === "OK") {
                  setData(response.data.analytics);
                  setLoadingData(false)
                } else if (response.data.analytics["API response"] !== "OK" && fetchCount < 6) {
                  // Retry fetchData if the API response is not OK and the fetchCount is less than 6
                  fetchData();
                } else {
                  toast.error(` We’re still generating this report, please check back again here soon.`);
                  setLoadingData(false)
                }
              } else {
                toast.error(`Error. Please, try later.`);
                setLoadingData(false)
              }
            } else {
              toast.error(`Error. Please, try later.`);
              setLoadingData(false)
            }
          })
          .catch((err) => {
            console.error(`Error: ${err.message}`);
            if (fetchCount < 6) {
              fetchData();
            } else {
              setLoadingData(false)
              toast.error(`Error. Please, try later.`);
            }
          })
      };
      fetchData();
    }
  }, [company?.analyticsId, dateRange]);

  const dataVisitors = data?.visitors?.axis && fillDateRange(
    dateRange[0].startDate,
    dateRange[0].endDate,
    data.visitors.axis.map((axis, i) => ({
      date: axis,
      value: data.visitors.values[i],
    }))
  );

  const dataVisitorsTotal = data?.visitors?.values?.length ? data.visitors.values.reduce((a, b) => a + b) : 0;

  const dataClicks = data?.clicks?.axis && fillDateRange(
    dateRange[0].startDate,
    dateRange[0].endDate,
    data.clicks.axis.map((axis, i) => ({
      date: axis,
      value: data.clicks.values[i],
    }))
  );
  const dataClicksTotal = data?.clicks?.values?.length ? data.clicks.values.reduce((a, b) => a + b) : 0;

  const dataImpressions = data?.impressions?.axis && fillDateRange(
    dateRange[0].startDate,
    dateRange[0].endDate,
    data.impressions.axis.map((axis, i) => ({
      date: axis,
      value: data.impressions.values[i],
    }))
  );

  const dataImpressionsTotal =
    data?.impressions?.values?.length ? data.impressions.values.reduce((a, b) => a + b) : 0;

  const formatteDataImpressionsTotal = dataImpressionsTotal?.toLocaleString('en-US');

  const dataLocationsCounts = data?.region?.counts?.slice(0, 5);
  const dataLocationsPercentages = data?.region?.percentages?.slice(0, 5);
  const dataLocations = {
    categories: dataLocationsCounts?.map((x) => x[0]) || [],
    values: dataLocationsCounts?.map((x) => x[1]) || [],
    percentages: dataLocationsPercentages?.map((x) => x[1]) || [],
  };

  const GENDER_THRESHOLD = 1;

  const genderDataIsUnreliable =
    !data?.userGender?.counts?.[0] ||
    !data?.userGender?.counts?.[0]?.[1] ||
    !data?.userGender?.counts?.[1] ||
    !data?.userGender?.counts?.[1]?.[1] ||
    data?.userGender?.counts?.[0]?.[1] < GENDER_THRESHOLD ||
    data?.userGender?.counts?.[1]?.[1] < GENDER_THRESHOLD;

  const dataGender: [string, number][] = genderDataIsUnreliable ? [] : data?.userGender?.counts;
  const dataDevices = data?.deviceCategory?.counts;
  const dataAgesCounts = data?.userAgeBracket?.counts?.sort((a, b) => (a < b ? -1 : 1));
  const dataAgesPercentages = data?.userAgeBracket?.percentages?.sort((a, b) => (a < b ? -1 : 1));
  const dataAges = {
    categories: dataAgesCounts?.map((x) => x[0]) || [],
    values: dataAgesCounts?.map((x) => x[1]) || [],
    percentages: dataAgesPercentages?.map((x) => x[1]) || [],
  };

  const dataTime = data?.time_on_page?.axis &&fillDateRange(
    dateRange[0].startDate,
    dateRange[0].endDate,
    data.time_on_page.axis.map((axis, i) => ({
      date: axis,
      value: data.time_on_page.values[i],
    }))
  );
  /* const averageTime =
		data.time_on_page.values.length === 0
			? 0
			: data.time_on_page.values.reduce((a, b) => a + b) /
			  data.time_on_page.values.length; */
  const medianTime = data?.time_on_page?.values ? median(data.time_on_page.values) : '';

  let newUsersData: [string, number][] = data?.userType?.percentages || [];
  newUsersData = newUsersData.map(([key, value]) => [key, Math.round(value)])

  const getPointSelectionPostcode = (index) => {
    setPostcode(data.topPostcodes[index][0]);
  }

  return (!showSpinner && !company?.analyticsId && !Object.keys(fullData?.analytics).length) || !company?.subscribed ? (
    <UpgradeBlock
      showSpinner={false}
      text="Get priority featured, traffic sent to your website and online reporting."
      icon={<img src="/media/menu/analytics.png" alt="chart" className="w-125px h-125px" />}
    />
  ) : (
    <div className="position-relative">
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar />

      <div className="position-relative mx-4 mx-md-14 z-1">
        {/*<Container fluid="sm">*/}
        {/*<Row>*/}
        {isSuperAdmin && (
          <Row className="justify-content-center">
            <Col sm={11}>
              <div>
                <button className="btn btn-success my-3" onClick={() => setSelectModalIsOpen(true)}>
                  Company select
                </button>
              </div>
            </Col>
          </Row>
        )}

        <Row className="justify-content-center">
          <Col sm={11}>
            <div className="d-flex justify-content-between align-items-center flex-wrap mb-10 mt-3">
              <h2 className="fs-h1-big fw-bolder text-gray-950 my-3 me-3">{fullData.name}</h2>
              <CalendarWidget today={today} dateRange={dateRange} setDateRange={setDateRange} />
            </div>
          </Col>

          <Col sm={11} className="mb-14">
            <ChartBlock
              className={{ block: 'pb-14', title: 'ms-4' }}
              title={`Profile Pageviews - ${dataVisitorsTotal.toLocaleString('en-US')}`}
              subtitle={`${fullData.name} Top Property Profile Page`}
            >
              <AreaChart
                label="Pageviews"
                data={dataVisitors}
                width={largeWidth}
                height={largeHeight * 1.5}
                strokeColor={COLOR.pageviews}
              />
            </ChartBlock>
          </Col>

          <Col sm={11} className="mb-14">
            <ChartBlock
              className={{ block: 'pb-14', title: 'ms-4' }}
              title={`ClickStream - ${dataClicksTotal.toLocaleString('en-US')}`}
              subtitle={`Top Property visitors sent to the ${fullData.name} website`}
            >
              <AreaChart
                label="Clicks"
                data={dataClicks}
                width={largeWidth}
                height={largeHeight * 1.5}
                strokeColor={COLOR.clickstream}
              />
            </ChartBlock>
          </Col>

          <Col sm={11} className="mb-14">
            <ChartBlock
              className={{ block: 'pb-14', title: 'ms-4' }}
              title={`Logo & Brand Name Views - ${formatteDataImpressionsTotal.toLocaleString('en-US')}`}
              subtitle={`Local online exposure generated for ${fullData.name}`}
            >
              <AreaChart
                label="Logo & Brand Name Views"
                data={dataImpressions}
                width={largeWidth}
                height={largeHeight}
                strokeColor={COLOR.logoBrand}
              />
            </ChartBlock>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col sm={11} className="mb-14">
            <Row className="shadow-sm rounded-3 mx-1">
              <Col md={6} className="px-0">
                <ChartBlock
                    className={{ title: 'ms-4', block: 'shadow-none', subtitle: 'mb-n1' }}
                    title="Top Postcodes"
                    subtitle={`Where Top Property homeowners interested in ${fullData.name} are located.`}
                >
                  <BarChart
                      getPointSelection={getPointSelectionPostcode}
                      categories={data?.topPostcodes?.map((x) => `${x[0]}`) || []}
                      values={data?.topPostcodes?.map((x) => x[1]) || []}
                      width={smallWidth}
                      height={largeHeight * 1.5}
                      strokeColor={COLOR.top5}
                      extraOptions={{
                        tooltip: {
                          enabled: false,
                        },
                        xaxis: {
                          labels: {
                            show: false,
                          },
                          axisBorder: {
                            show: false,
                          },
                          axisTicks: {
                            show: false,
                          },
                        },
                        grid: {
                          show: false,
                        },
                      }}
                  />
                </ChartBlock>
              </Col>

              <Col md={6} className="px-0">
                {latCoord && (
                    <Map
                        zoom={9}
                        centerPosition={{
                          lat: +latCoord,
                          lng: +lngCoord,
                        }}
                        containerStyle={{
                          width: "100%",
                          height: largeHeight * 1.5 + 130,
                          // borderRadius: "15px",
                        }}
                    />
                )}
              </Col>
            </Row>

          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col sm={11}>
            <Row>
              <Col md={6} lg={4} className="mb-14">
                <ChartBlock titleCenter title="Top Cities">
                  <ListChart
                    header=""
                    data={dataLocations}
                    tooltip="👋 This chart shows the top five performing cities for the date range selected. Due to internet privacy and tracking limitations, location data is approximate and is based on the individual IP address of each user at the city level, and not the GPS location for that user. This IP address data is dependent on the internet service provider that person is with, plus where that internet service provider has their nearest data centre. 📡"
                    width={smallWidth}
                    height={listHeight}
                    colors={[COLOR.clickstream, COLOR.pageviews, COLOR.logoBrand, COLOR.top4, COLOR.top5]}
                  />
                </ChartBlock>
              </Col>

              <Col md={6} lg={4} className="mb-14">
                <ChartBlock titleCenter title="Avg Time On Page" className={{ block: 'pt-0 pe-0 ps-0' }}>
                  <h3 className="my-14 fs-h1-big text-center">{timeFormat(medianTime)}</h3>
                  <AreaChart
                    label="Time"
                    data={dataTime}
                    width={largeWidth}
                    height={126}
                    strokeColor={COLOR.top5}
                    extraOptions={{
                      chart: {
                        sparkline: {
                          enabled: true,
                        },
                      },
                      fill: {
                        gradient: {
                          opacityTo: 0.3,
                          // stops: [60, 100],
                        },
                      },
                      xaxis: {
                        labels: {
                          show: false,
                        },
                        axisBorder: {
                          show: false,
                        },
                        axisTicks: {
                          show: false,
                        },
                      },
                      yaxis: {
                        show: false,
                      },
                      grid: {
                        show: false,
                      },
                      tooltip: {
                        enabled: false,
                      },
                    }}
                  />
                </ChartBlock>
              </Col>

              <Col md={6} lg={4} className="mb-14">
                <ChartBlock titleCenter title="Engagement">
                  <RadialChart
                    data={newUsersData}
                    width={smallWidth}
                    height={295}
                    colors={[COLOR.top5, COLOR.clickstream]}
                  />
                </ChartBlock>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col sm={11}>
            <Row>
              <Col lg={6} className="mb-14">
                <ChartBlock className={{ title: 'ms-4' }} title="Age">
                  <BarChart
                    categories={dataAges?.categories || []}
                    values={dataAges?.values || []}
                    width={smallWidth}
                    height={largeHeight}
                    strokeColor={COLOR.top5}
                    extraOptions={{
                      tooltip: {
                        enabled: false,
                      },
                      xaxis: {
                        labels: {
                          show: false,
                        },
                        axisBorder: {
                          show: false,
                        },
                        axisTicks: {
                          show: false,
                        },
                      },
                      grid: {
                        show: false,
                      },
                    }}
                  />
                </ChartBlock>
              </Col>
              <Col lg={6} className="mb-14">
                <ChartBlock className={{ block: 'pt-0 pe-8 ps-md-8 ps-xl-15 pe-md-12' }} titleCenter title="Gender">
                  {!showSpinner && (
                    <PieChart
                      header=""
                      data={dataGender}
                      tooltip="👋 This chart shows the estimated percentage of female versus male visitors on your Top Property Professionals page for the date range selected. This information can take a little bit longer for our reporting system to show because gender statistics requires a bigger volume of page visitors to capture enough tracking data to generate. 🙍‍♀️🙎‍♂️"
                      height={300}
                      colors={[COLOR.top4, COLOR.top5]}
                      isDonut
                    />
                  )}
                </ChartBlock>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col sm={11} className="mb-14">
            <ChartBlock className={{ block: 'px-md-15' }} title="Devices Used">
              {!showSpinner && (
                <PieChart
                  header=""
                  data={dataDevices}
                  tooltip="👋 This chart shows what devices people used to view your Top Property Professionals page for the date range selected. This data can help to inform you about the user behaviour of your visitors, so you can understand how people are searching and learning about your business.📱🖥💻"
                  height={410}
                  colors={[COLOR.clickstream, COLOR.top4, COLOR.logoBrand]}
                  isDonut
                />
              )}
            </ChartBlock>
          </Col>
        </Row>

        {selectModalIsOpen && <SelectCompanyModal setSelectModalIsOpen={setSelectModalIsOpen} />}
        {/*</Container>*/}
      </div>

      {showSpinner && (
        <div className="position-absolute top-0 bottom-0 start-0 end-0 bg-dark bg-opacity-25 z-2">
          <HypnosisLoader size={100} style={{ height: '80vh' }} />
        </div>
      )}
    </div>
  );
};
