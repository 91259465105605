import { SidebarMenuMain } from './SidebarMenuMain';
import { useContext } from 'react';
import { FileContext } from 'app/modules/accounts/Filecontext';
import { useCompany } from 'hooks/useCompany';
import SVG from 'react-inlinesvg';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';

const SidebarMenu = () => {
  const { selectedCompany } = useContext(FileContext);
  const companyId = selectedCompany || localStorage.getItem('companyId');
  const { company, isLoading, refetch, isFetching } = useCompany(companyId as number);

  const showModule = !isLoading && company?.subscribed === false;

  return (
    <div className="app-sidebar-menu overflow-hidden flex-column-fluid">
      <div
        id="kt_app_sidebar_menu_wrapper"
        className="app-sidebar-wrapper hover-scroll-overlay-y my-5"
        data-kt-scroll="true"
        data-kt-scroll-activate="true"
        data-kt-scroll-height="auto"
        data-kt-scroll-dependencies="#kt_app_sidebar_logo, #kt_app_sidebar_footer"
        data-kt-scroll-wrappers="#kt_app_sidebar_menu"
        data-kt-scroll-offset="5px"
        data-kt-scroll-save-state="true"
      >
        <div
          className="menu menu-column menu-rounded menu-sub-indention"
          id="#kt_app_sidebar_menu"
          data-kt-menu="true"
          data-kt-menu-expand="false"
        >
          <div className="px-5">
            <SidebarMenuMain />
          </div>
          {showModule && (
            <div className="app-sidebar-footer flex-column-auto pt-2 pb-6 px-6" id="kt_app_sidebar_footer">
              <div className="px-3 px-md-5 py-4 py-md-6 bg-gray-150 rounded-3">
                <SVG src="/media/svg/general/rocket.svg" style={{ width: '55px', height: '55px' }} />

                <h6 className="mt-6 mb-0 fs-h2 font-medium">Upgrade My Account</h6>

                <p className="mt-3 mb-6 mb-0 fs-h4 font-secondary font-normal">
                  Get priority featured, drive traffic to your website and capture more enquires.
                </p>

                <Link to="/settings/plan">
                  <Button size="sm" variant="dark">
                    Upgrade
                  </Button>
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export { SidebarMenu };
