import { format, timeDay } from 'd3';
import { addDays } from 'date-fns';
import React from 'react';

export let scaleWidth = 1;
export let scaleHeight = 1;
export let largeWidth = 720 * scaleWidth; // 576
export let largeHeight = 260 * scaleHeight; // 208
export let listHeight = 240 * scaleHeight;
export let smallWidth = 300 * scaleWidth; // 240
// let smallHeight = 350 * scaleHeight; // 280

export function fillDateRange(start: Date, end: Date, range: { date: string; value: number }[]) {
  let fullRange: { date: string; value: number }[] = [];

  let timeRange = timeDay.range(start, addDays(end, 1));

  for (let i = 0; i < timeRange.length; i++) {
    let date = timeRange[i].toISOString().split('T')[0];
    let value = range.find((v) => v.date === date)?.value;
    fullRange.push({ date: date, value: value || 0 });
  }

  if (fullRange.length === 1) {
    fullRange.push(fullRange[0]);
  }

  return fullRange;
}

export const median = (data: number[]) => {
  const filteredData = data.filter((x) => x > MIN_TIME_ON_PAGE);
  const arrSort = filteredData.sort();
  const len = arrSort.length;
  const mid = Math.ceil(len / 2);
  return len % 2 === 0 ? (arrSort[mid] + arrSort[mid - 1]) / 2 : arrSort[mid - 1];
};
export const timeFormat = (d: number) => {
  if (!d) {
    return 'N/A';
  } else if (d < 61) {
    return `${Math.ceil(d)} seconds`;
  } else {
    return `${format('.0f')(d.valueOf() / 60)}:${format('0>2.0f')(d.valueOf() % 60)} min`;
  }
};

export function DashboardIsLoading() {
  return <div className="loader" aria-label="Loading…"></div>;
}

export const MIN_TIME_ON_PAGE = 5;

type OptionsProps = {
  strokeWidth?: number;
  height?: number;
  colors: string[];
  categories: [];
  series: [];
};
export const areaOptions = (props: OptionsProps) => {
  const { height, colors, categories, strokeWidth = 3, series } = props;
  return {
    series: [
      {
        data: series,
      },
    ],
    chart: {
      height,
      type: 'area',
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: true,
        tools: {
          download: false,
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      width: strokeWidth,
      colors,
    },
    fill: {
      type: 'gradient',
      gradient: {
        type: 'vertical',
        shadeIntensity: 0.5,
        gradientToColors: colors,
        inverseColors: true,
        opacityFrom: 0.75,
        opacityTo: 0,
        stops: [60, 100],
        colorStops: [],
      },
      xaxis: {
        type: 'datetime',
        categories,
        labels: {
          datetimeFormatter: {
            year: 'yyyy',
            month: 'MMMM',
            day: 'MMM dd',
            hour: 'HH:mm',
          },
        },
      },
      tooltip: {
        enabled: false,
        style: {
          fontSize: '13px',
        },
        x: {
          format: 'dd/MM/yy',
        },
      },
    },
    colors,
  };
};

export const radialOptions = (props: OptionsProps) => {
  const { height, colors, categories, series } = props;
  // @ts-ignore
  const totalLabel = categories[0] || '';
  // @ts-ignore
  const totalVal = series[0] || '';
  return {
    series,
    chart: {
      height,
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        dataLabels: {
          name: {
            fontSize: '15px',
            fontWeight: 400,
            fontFamily: 'DM Sans, serif',
          },
          value: {
            fontSize: '22px',
            fontWeight: 500,
          },
          total: {
            show: true,
            color: colors[0],
            label: totalLabel,
            formatter: function (w: any) {
              // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
              return `${totalVal}%`;
            },
          },
        },
      },
    },
  };
};

export function newAbortSignal(timeoutMs) {
  const abortController = new AbortController();
  setTimeout(() => abortController.abort(), timeoutMs || 0);

  return abortController.signal;
}
