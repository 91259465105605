import './style.css';
import clsx from 'clsx';

const HypnosisLoader = ({
  size,
  className,
  style,
}: {
  size?: number;
  className?: string;
  style?: { [key: string]: string };
}) => {
  return (
    <div className={clsx('d-flex justify-content-center align-items-center', className)} style={style}>
      <div className={clsx('loader-hypnosis', size ? `w-${size}px h-${size}px` : 'w-55px h-55px')}>
        <div className="outer-1"></div>
        <div className="middle-1"></div>
        <div className="inner-1"></div>
      </div>
    </div>
  );
};

export default HypnosisLoader;
