import React, { Dispatch, FC, SetStateAction } from 'react';
import { Modal } from 'react-bootstrap';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl } from '../../../_metronic/helpers';
import ReactPlayer from 'react-player/lazy';

type YoutubeModalProps = {
  setModalIsOpen: Dispatch<SetStateAction<boolean>>;
  show: boolean;
};

const YoutubeModal: FC<YoutubeModalProps> = ({ setModalIsOpen, show }) => {
  return (
    <Modal
      show={show}
      onHide={() => setModalIsOpen(false)}
      aria-labelledby="example-custom-modal-styling-title"
      size="lg"
      centered={true}
    >
      {/*<Modal.Header className="d-flex justify-content-end align-items-center pt-2 pb-0">*/}
      {/*  */}
      {/*</Modal.Header>*/}

      <Modal.Body className="position-relative">
        <div
            className="btn btn-icon btn-sm btn-active-icon-primary position-absolute top-0 end-0 mt-3 mx-3"
            data-kt-users-modal-action="close"
            onClick={() => setModalIsOpen(false)}
            style={{ cursor: 'pointer' }}
        >
          <span className="position-absolute translate-20 cursor-pointer bg-white rounded-circle">
            <SVG src={toAbsoluteUrl('/media/svg/general/close-black.svg')} />
          </span>
        </div>
        <div className="d-flex justify-content-center my-4">
          <ReactPlayer url="https://youtu.be/sZRCF_F19zw"
                       controls={true}
                       style={{borderRadius: "10px", overflow: "hidden"}}/>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default YoutubeModal;
