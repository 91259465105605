import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import { KTSVG } from '../../../_metronic/helpers';
import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';

type CreateCompanyModalProps = {
  setCreateModalIsOpen: Dispatch<SetStateAction<boolean>>;
  refetch: () => void;
};

type CompanyFormType = {
  name: string;
  alias: string;
};

const validationCompanySchema = Yup.object().shape({
  name: Yup.string().max(36, "Name must be shorter than 36 characters.").required('Company name is required.'),
  alias: Yup.string().required('Alias is required.'),
});

const CreateCompanyModal: FC<CreateCompanyModalProps> = ({ setCreateModalIsOpen, refetch }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const createCompany = async (values: CompanyFormType) => {
    setIsLoading(true);
    try {
      const result = await axios.post(`${process.env.REACT_APP_API_URL}/company`, values);
      toast.success(`Created Company with ID ${result.data.id}`);
    } catch (error) {
      toast.error(`Error: ${error}`);
    } finally {
      setIsLoading(false);
      setCreateModalIsOpen(false);
      refetch();
    }
  };

  const initialCompanyValues = { name: '', alias: '' };

  return (
    <div>
      <div className="modal fade show d-block" aria-modal="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="fw-bolder">Create Company</h2>
              <div
                className="btn btn-icon btn-sm btn-active-icon-primary"
                data-kt-users-modal-action="close"
                onClick={() => setCreateModalIsOpen(false)}
                style={{ cursor: 'pointer' }}
              >
                <KTSVG path="/media/icons/duotune/arrows/arr061.svg" className="svg-icon-1" />
              </div>
              {/* end::Close */}
            </div>
            <div className={'modal-body'}>
              <Formik
                initialValues={initialCompanyValues}
                validationSchema={validationCompanySchema}
                onSubmit={createCompany}
              >
                {({ errors, touched, isValid, dirty }) => (
                  <Form>
                    <div className="position-relative mb-8 ">
                      <Field
                        name="name"
                        type="text"
                        placeholder="Company Name"
                        className={`form-control py-5 rounded-3 fs-h3 ${
                          touched.name && errors.name ? 'is-invalid' : ''
                        }`}
                      />
                      <label
                        htmlFor="message"
                        className="position-absolute top-n18 start-30px m-0 px-2 form-label fs-h3 fw-normal bg-white text-capitalize"
                      >
                        Name
                      </label>
                      <ErrorMessage className="invalid-feedback" name="name" component="div" />
                    </div>
                    <div className="position-relative mb-8 ">
                      <Field
                        name="alias"
                        type="text"
                        placeholder="Company Alias"
                        className={`form-control py-5 rounded-3 fs-h3 ${
                          touched.alias && errors.alias ? 'is-invalid' : ''
                        }`}
                      />
                      <label
                        htmlFor="message"
                        className="position-absolute top-n18 start-30px m-0 px-2 form-label fs-h3 fw-normal bg-white text-capitalize"
                      >
                        Alias
                      </label>
                      <ErrorMessage className="invalid-feedback" name="alias" component="div" />
                    </div>
                    <div className="d-flex justify-content-center align-items-center mt-5">
                      <button disabled={!isValid || !dirty || isLoading} className="btn btn-dark my-5" type="submit">
                        <span className="indicator-label">Create company</span>
                        {isLoading && <span className="spinner-border spinner-border-sm align-middle ms-2"></span>}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </div>
  );
};

export { CreateCompanyModal };
