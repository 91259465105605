import clsx from 'clsx';
import { KTSVG, toAbsoluteUrl } from '../../../helpers';
import { HeaderNotificationsMenu, HeaderUserMenu, Search, ThemeModeSwitcher } from '../../../partials';
import { useLayout } from '../../core';
import { useAuth } from '../../../../app/modules/auth';
import { useEffect, useState } from 'react';
import { getAsset } from '../../../../helpers/getAsset';
import { useCompany } from '../../../../hooks/useCompany';
import HeaderUserInfo from './HeaderUserInfo';
import HeaderUser from '../../../partials/layout/header-menus/HeaderUser';

const itemClass = 'ms-1 ms-lg-3';
const btnClass =
  'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px';
const userAvatarClass = 'symbol-35px symbol-md-40px';
const btnIconClass = 'svg-icon-1';

const Navbar = () => {
  const { config } = useLayout();

  const { currentUser } = useAuth();

  const [logoUrl, setLogoUrl] = useState<string>('');

  const id = currentUser?.companyId || localStorage.getItem('companyId');
  const companyId = id ? +id : 0;
  const getLogo = async (logoKey: string) => {
    const logoUrl = await getAsset(logoKey);
    if (typeof logoUrl == 'string') setLogoUrl(logoUrl);
  };
  const { company, isLoading, refetch, isFetching } = useCompany(companyId);
  const finalLogoKey = company?.croppedLogoLocation || company?.logoLocation;

  useEffect(() => {
    finalLogoKey && getLogo(finalLogoKey);
  }, [finalLogoKey]);

  return (
    <div className="app-navbar flex-shrink-0">
      {process.env.REACT_APP_ENV === 'dev' && (
        <>
          <div className="app-navbar">
            <div className={clsx('app-navbar-item align-items-stretch', itemClass)}>
              <Search />
            </div>

            <div className={clsx('app-navbar-item', itemClass)}>
              <div id="kt_activities_toggle" className={btnClass}>
                <KTSVG path="/media/icons/duotune/general/gen032.svg" className={btnIconClass} />
              </div>
            </div>

            <div className={clsx('app-navbar-item', itemClass)}>
              <div
                data-kt-menu-trigger="{default: 'click'}"
                data-kt-menu-attach="parent"
                data-kt-menu-placement="bottom-end"
                className={btnClass}
              >
                <KTSVG path="/media/icons/duotune/general/gen022.svg" className={btnIconClass} />
              </div>
              <HeaderNotificationsMenu />
            </div>
            <div className={clsx('app-navbar-item', itemClass)}>
              <div className={clsx('position-relative', btnClass)} id="kt_drawer_chat_toggle">
                <KTSVG path="/media/icons/duotune/communication/com012.svg" className={btnIconClass} />
                <span className="bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink" />
              </div>
            </div>
          </div>

          {/*<div className={clsx('app-navbar-item', itemClass)}>*/}
          {/*  <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />*/}
          {/*</div>*/}
          <div className={clsx('app-navbar-item', itemClass)}>
            <div className={clsx('position-relative bg-gray-200 rounded-circle', btnClass)} id="kt_drawer_chat_toggle">
              <KTSVG path="/media/menu/bell.svg" className={btnIconClass} />
              {/*<span className="bullet bullet-dot bg-success position-absolute translate-middle top-0 end-0 translate-20 animation-blink" />*/}
            </div>
          </div>
        </>
      )}
      <div className={clsx('app-navbar-item', itemClass)}>
        <HeaderUser userAvatarClass={userAvatarClass} logoUrl={logoUrl} companyName={company?.name} />

        <div className="d-none d-md-flex">
          <HeaderUserInfo user={currentUser?.email} companyName={company?.name} />
        </div>
      </div>
    </div>
  );
};

export { Navbar };
