import { Suspense, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { I18nProvider } from '../_metronic/i18n/i18nProvider';
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core';
import { MasterInit } from '../_metronic/layout/MasterInit';
import { AuthInit, useAuth } from './modules/auth';
import { Amplify } from 'aws-amplify';
import awsmobile from '../aws-exports';
import {
  BrandType,
  DetailsType,
  FileContext,
  LocationsType,
  OpeningHoursType,
  ServiceType,
  SocialType,
  VideoType,
} from './modules/accounts/Filecontext';
import BubbleImage from './components/LiveChatButton';

Amplify.configure(awsmobile);

const App = () => {
  const { currentUser } = useAuth();
  const [details, setDetails] = useState<DetailsType | undefined>();
  const [locations, setLocations] = useState<LocationsType | undefined>();
  const [aboutUsDescription, setAboutUsDescription] = useState<string | undefined>();
  const [locWeServDescription, setLocWeServDescription] = useState<string | undefined>();
  const [social, setSocial] = useState<SocialType | undefined>();
  const [cntxBrands, setCntxBrands] = useState<BrandType[] | undefined>();
  const [cntxServices, setCntxServices] = useState<ServiceType[] | undefined>();
  const [openingHours, setOpeningHours] = useState<OpeningHoursType[] | undefined>();
  const [videos, setVideos] = useState<VideoType[] | undefined>();
  const [selectedCompany, setSelectedCompany] = useState<number | undefined>(currentUser?.companyId);
  const resetContext = () => {
    setDetails(undefined);
    setLocations(undefined);
    setAboutUsDescription(undefined);
    setLocWeServDescription(undefined);
    setSocial(undefined);
    setCntxBrands(undefined);
    setCntxServices(undefined);
    setOpeningHours(undefined);
    setVideos(undefined);
    setSelectedCompany(undefined);
  };

  useEffect(() => {
    setSelectedCompany(currentUser?.companyId);
  }, [currentUser]);
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <AuthInit>
            <FileContext.Provider
              value={{
                selectedCompany,
                setSelectedCompany,
                details,
                setDetails,
                locations,
                setLocations,
                aboutUsDescription,
                setAboutUsDescription,
                locWeServDescription,
                setLocWeServDescription,
                social,
                setSocial,
                cntxBrands,
                setCntxBrands,
                cntxServices,
                setCntxServices,
                openingHours,
                setOpeningHours,
                videos,
                setVideos,
                resetContext,
              }}
            >
              <Outlet />
              <MasterInit />
            </FileContext.Provider>
            <BubbleImage />
          </AuthInit>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  );
};

export { App };
