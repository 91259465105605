import { Route, Routes } from 'react-router-dom';
// import { Registration } from './components/Registration';
import { ForgotPassword } from './components/ForgotPassword';
import { Login } from './components/Login';
import { AuthLayout } from './AuthLayout';
import InvitationForm from './components/InvitationForm';

const AuthPage = () => (
  <Routes>
    <Route path="login" element={<Login />} />
    {/* <Route path="registration" element={<Registration />} /> */}
    <Route path="forgot-password" element={<ForgotPassword />} />
    <Route path="change-password" element={<InvitationForm />} />
    <Route index element={<Login />} />
  </Routes>
);

export { AuthPage };
