import React, { lazy, FC, Suspense, useState, useContext } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { MasterLayout } from '../../_metronic/layout/MasterLayout';
import TopBarProgress from 'react-topbar-progress-indicator';
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper';
import { MenuTestPage } from '../pages/MenuTestPage';
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils';
import { WithChildren } from '../../_metronic/helpers';
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper';
import { useAuth } from '../modules/auth';
import { Dashboard } from 'app/modules/reports-ga/components/dashboard';
import GlobalSettingPage from '../modules/settings/GlobalSettingPage';
import platform from 'platform';
import { checkBrowserVersion } from "../../helpers/utils";

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'));
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'));
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'));
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'));
  const ChatPage = lazy(() => import('../modules/chat/ChatPage'));
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'));
  const ProjectsPage = lazy(() => import('../modules/projects/ProjectsPage'));
  const CompanySelectPage = lazy(() => import('../modules/companyselect/CompanySelectPage'));
  const SettingPage = lazy(() => import('../modules/settings/SettingPage'));
  const { currentUser } = useAuth();

  const isSuperAdmin = currentUser?.isSuperAdmin;

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        {isSuperAdmin ? (
          <Route path="auth/*" element={<Navigate to="/company-select" />} />
        ) : (
          <Route path="auth/*" element={<Navigate to="/profile/details" />} />
        )}
        {/* Pages */}
        <Route path="dashboard" element={<DashboardWrapper />} />
        <Route path="builder" element={<BuilderPageWrapper />} />
        <Route path="menu-test" element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path="crafted/pages/profile/*"
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path="crafted/pages/wizards/*"
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path="crafted/widgets/*"
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        {/*<Route*/}
        {/*  path="crafted/account/*"*/}
        {/*  element={*/}
        {/*    <SuspensedView>*/}
        {/*      <AccountPage />*/}
        {/*    </SuspensedView>*/}
        {/*  }*/}
        {/*/>*/}
        <Route
          path="profile/*"
          element={
            <SuspensedView>
                {
                    platform.name === "Safari" && !checkBrowserVersion(platform.version, 16, 5) ? (
                        <div className="d-flex justify-content-center align-items-center min-vh-100">
                            <h2 className="mb-15">Please, upgrade your browser!</h2>
                        </div>
                    ) : (
                        <AccountPage />
                    )
                }
            </SuspensedView>
          }
        />
        <Route
          path={'projects/*'}
          element={
            <SuspensedView>
              <ProjectsPage />
            </SuspensedView>
          }
        />
        <Route
          path={'analytics/*'}
          element={
            <SuspensedView>
              <Dashboard />
            </SuspensedView>
          }
        />
        {isSuperAdmin && (
          <Route
            path={'company-select/'}
            element={
              <SuspensedView>
                <CompanySelectPage isModal={false} />
              </SuspensedView>
            }
          />
        )}

        <Route
          path={'settings/*'}
          element={
            <SuspensedView>
              <SettingPage />
            </SuspensedView>
          }
        />

        {isSuperAdmin && (
          <Route
            path={'global-settings/'}
            element={
              <SuspensedView>
                <GlobalSettingPage />
              </SuspensedView>
            }
          />
        )}

        <Route
          path="apps/chat/*"
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path="apps/user-management/*"
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary');
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
