import React, { Dispatch, FC, SetStateAction } from 'react';
import { KTSVG } from '_metronic/helpers';
import { CompaniesTable } from '../CompanySelect/CompaniesTable';

type SelectCompanyModalProps = {
  setSelectModalIsOpen: Dispatch<SetStateAction<boolean>>;
};

const SelectCompanyModal: FC<SelectCompanyModalProps> = ({ setSelectModalIsOpen }) => {
  return (
    <>
      <div className="modal fade show d-block" aria-modal="true">
        <div className="modal-dialog modal-dialog-centered mw-900px ">
          <div className="modal-content h-1000px">
            <div className="modal-header">
              <h2 className="fw-bolder">Select Company</h2>
              <div
                className="btn btn-icon btn-sm btn-active-icon-primary"
                data-kt-users-modal-action="close"
                onClick={() => setSelectModalIsOpen(false)}
                style={{ cursor: 'pointer' }}
              >
                <KTSVG path="/media/icons/duotune/arrows/arr061.svg" className="svg-icon-1" />
              </div>
              {/* end::Close */}
            </div>
            <div className={'modal-body m-0 p-0'}>
              <CompaniesTable isModal={true} className={''} closeModal={setSelectModalIsOpen} />
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </>
  );
};

export { SelectCompanyModal };
