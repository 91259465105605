export const DEBUG = process.env.NODE_ENV === 'development';

export const COLOR = {
  black1: '#000000',
  white: '#FFFFFF',
  black2: '#010101',
  grey: '#A3A3A3',
  purple1: '#6556F2',
  aquamarine1: '#5AA7D7',
  black3: '#1E2E42',
  purple2: '#694EFE',
  purple3: '#694EFF',
  red: '#EA6561',
  yellow: '#F7D074',
  blue: '#7EC3E7',
  purple4: '#9A88FF',
  black4: '#2F2F30',
  gradientStart: '#F4F2FFFF',
  gradientEnd: '#F4F2FF00',
  gradientAdsStart: '#93b9d1',
  gradienAdstEnd: '#128ad6',
  gradientSmallStart: '#694EFEFF',
  gradientSmallEnd: '#694EFE00',
  gradientAdsSmallStart: '#f9eed5',
  gradientAdsSmallEnd: '#f9b821',
  backgroundDark: '#ECE7F8',
  backgroundLight: '#F5F3FE',
  darkBlue: '#3378F5',
  green: '#65C467',
  royalBlue: '#5856CF',
  darkOrange: '#F19A38',
  logoBrand: '#f5d748',
  clickstream: '#7ad6f0',
  pageviews: '#655ADF',
  top4: '#E63290',
  top5: '#367BF6',
  label: '#0B0A12',
};
