import { FC } from 'react';
import { useLocation } from 'react-router';
import { checkIsActive } from '../../../../helpers';
import { Container } from 'react-bootstrap';

type Props = {
  to: string;
  title: string;
  icon?: string;
  fontIcon?: string;
  hasArrow?: boolean;
  hasBullet?: boolean;
};

const MenuItem: FC<Props> = ({ to, title, icon, fontIcon, hasArrow = false, hasBullet = false }) => {
  const { pathname } = useLocation();
  const isActive = checkIsActive(pathname, to);
  return isActive ? (
    <Container className="d-flex align-items-center">
      <h2 className="fs-h1 fw-semibold m-0">{title}</h2>
    </Container>
  ) : null;
  //   (
  //   <div className='menu-item me-lg-1'>
  //     <Link
  //       className={clsx('menu-link py-3', {
  //         'active menu-here': checkIsActive(pathname, to),
  //       })}
  //       to={to}
  //     >
  //       {hasBullet && (
  //         <span className='menu-bullet'>
  //           <span className='bullet bullet-dot'></span>
  //         </span>
  //       )}
  //
  //       {icon && (
  //         <span className='menu-icon'>
  //           <KTSVG path={icon} className='svg-icon-2' />
  //         </span>
  //       )}
  //
  //       {fontIcon && (
  //         <span className='menu-icon'>
  //           <i className={clsx('bi fs-3', fontIcon)}></i>
  //         </span>
  //       )}
  //
  //       <span className='menu-title'>{title}</span>
  //
  //       {hasArrow && <span className='menu-arrow'></span>}
  //     </Link>
  //   </div>
  // )
};

export { MenuItem };
