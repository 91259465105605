// @ts-nocheck
import { timeFormat } from 'd3';
import {addDays, addMonths, differenceInCalendarMonths, isSameDay, startOfQuarter, startOfYear} from 'date-fns';
import { Range } from '../api/interfaces';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { createStaticRanges, DateRangePicker } from 'react-date-range';
import Popup from 'reactjs-popup';
import 'react-date-range/dist/styles.css'; // Import the main styles
import 'react-date-range/dist/theme/default.css';
import SVG from 'react-inlinesvg';
import { Button } from 'react-bootstrap';

const formatTime = timeFormat('%e %b %y');

function getStaticRanges(today: Date) {
  return createStaticRanges([
    {
      label: 'This Month',
      range: () => ({
        startDate: addMonths(today, -1),
        endDate: today,
      }),
      isSelected: (range: { startDate?: Date; endDate?: Date }) => {
        return (
          range.startDate !== undefined &&
          range.endDate !== undefined &&
          isSameDay(range.endDate, today) &&
          isSameDay(range.startDate, addMonths(today, -1))
        );
      },
    },
    {
      label: 'This Quarter',
      range: () => ({
        startDate: startOfQuarter(today),
        endDate: today,
      }),
      isSelected: (range: { startDate?: Date; endDate?: Date }) => {
        return (
          range.startDate !== undefined &&
          range.endDate !== undefined &&
          isSameDay(range.endDate, today) &&
          isSameDay(range.startDate, startOfQuarter(today))
        );
      },
    },
    {
      label: 'This Year',
      range: () => ({
        startDate: startOfYear(today),
        endDate: today,
      }),
      isSelected: (range: { startDate?: Date; endDate?: Date }) => {
        return (
          range.startDate !== undefined &&
          range.endDate !== undefined &&
          isSameDay(range.endDate, today) &&
          isSameDay(range.startDate, startOfYear(today))
        );
      },
    },
  ]);
}

function getInputRanges(today: Date, minDate: Date) {
  return [
    {
      label: 'months up to today',
      range: (value: string) => {
        let startDate = addMonths(today, (Math.max(Number(value), 1) - 1) * -1);
        if (startDate < minDate) {
          startDate = minDate;
        }
        return {
          startDate: startDate,
          endDate: today,
        };
      },
      getCurrentValue: (range: { startDate?: Date; endDate?: Date }) => {
        if (!range.startDate || !range.endDate || !isSameDay(range.endDate, today)) return '-';
        if (!range.startDate) return '∞';
        return (differenceInCalendarMonths(today, range.startDate) + 1).toString();
      },
      isSelected: (range: { startDate?: Date; endDate?: Date }) => {
        return (
          range.startDate !== undefined &&
          range.endDate !== undefined &&
          isSameDay(range.endDate, today) &&
          range.startDate.getDay() === today.getDay()
        );
      },
    },
  ];
}

export function CalendarWidget(props: {
  today: Date;
  dateRange: Range[];
  setDateRange: Dispatch<SetStateAction<Range[]>>;
}) {
  const [dateRangeTemp, setDateRangeTemp] = useState<Range[]>(props.dateRange);
  const minDate = new Date(2021, 0, 1);
  const staticRanges = getStaticRanges(props.today);
  const inputRanges = getInputRanges(props.today, minDate);
  let startDateName = formatTime(props.dateRange[0].startDate).trimStart();
  let endDateName = formatTime(props.dateRange[0].endDate).trimStart();
  let datePickerLabel = startDateName === endDateName ? startDateName : `${startDateName} — ${endDateName}`;
  return (
    <Popup
      trigger={
        <button
          style={{
            boxSizing: 'border-box',
            boxShadow: `0px 4px 20px rgba(0, 0, 0, 0.07)`,
          }}
          className="d-flex align-items-center my-3 py-3 px-5 bg-white border-0 rounded-3 fs-h4 fw-normal"
        >
          <SVG src="/media/svg/general/calendar.svg" className="me-2" />
          {datePickerLabel} &nbsp;&nbsp;
          <SVG src="/media/icons/arrow-down.svg" className="ms-4" style={{ width: '11px', height: '6px' }} />
        </button>
      }
      contentStyle={{
        background: 'none',
        border: 'none',
        width: 'auto',
      }}
      modal
    >
      {(close: () => {}) => (
        <div className="res-calendar d-flex flex-column">
          <DateRangePicker
            onChange={(item) => {
              setDateRangeTemp([(item as { selection: Range }).selection]);
            }}
            showSelectionPreview={false}
            moveRangeOnFirstSelection={false}
            months={1}
            ranges={dateRangeTemp}
            minDate={minDate}
            staticRanges={staticRanges}
            inputRanges={inputRanges}
            direction="horizontal"
            maxDate={addDays(new Date(), 1)}
          />
          <div className="d-flex flex-row justify-content-end align-items-start p-2 bg-white">
            <Button variant="danger" onClick={close} className="px-4 py-3 me-2">
              Cancel
            </Button>
            <Button
              variant="dark"
              className="px-4 py-3"
              onClick={() => {
                props.setDateRange(dateRangeTemp);
                close();
              }}
            >
              Apply
            </Button>
          </div>
        </div>
      )}
    </Popup>
  );
}
