import { createContext } from 'react';

export interface AppState {
  selectedCompany: number | undefined;
  setSelectedCompany: React.Dispatch<React.SetStateAction<number | undefined>>;
  details: DetailsType | undefined;
  setDetails: React.Dispatch<React.SetStateAction<DetailsType | undefined>>;
  locations: LocationsType | undefined;
  setLocations: React.Dispatch<React.SetStateAction<LocationsType | undefined>>;
  aboutUsDescription: string | undefined;
  setAboutUsDescription: React.Dispatch<React.SetStateAction<string | undefined>>;
  locWeServDescription: string | undefined;
  setLocWeServDescription: React.Dispatch<React.SetStateAction<string | undefined>>;
  social: SocialType | undefined;
  setSocial: React.Dispatch<React.SetStateAction<SocialType | undefined>>;
  cntxBrands: BrandType[] | undefined;
  setCntxBrands: React.Dispatch<React.SetStateAction<BrandType[] | undefined>>;
  cntxServices: ServiceType[] | undefined;
  setCntxServices: React.Dispatch<React.SetStateAction<ServiceType[] | undefined>>;
  openingHours: OpeningHoursType[] | undefined;
  setOpeningHours: React.Dispatch<React.SetStateAction<OpeningHoursType[] | undefined>>;
  videos: VideoType[] | undefined;
  setVideos: React.Dispatch<React.SetStateAction<VideoType[] | undefined>>;
  resetContext: () => void;
}

export interface DetailsType {
  businessName: string;
  slogan: string;
  category: string[];
  phone: string;
  mobile: string;
  website: string;
}

export interface LocationsType {
  streetNumber: string;
  address: string;
  province: string;
  state: string;
  postalCode: string;
}

export interface SocialType {
  google: string;
  facebook: string;
  youtube: string;
  instagram: string;
  linkedin: string;
  whatsapp: string;
  skype: string;
  twitter: string;
}

export interface BrandType {
  name: string;
  checked: boolean;
  id: number;
}

export interface ServiceType {
  name: string;
  checked: boolean;
  id: number;
}

export interface OpeningHoursType {
  start_hour: string;
  end_hour: string;
  weekday: number;
}

export interface VideoType {
  title: string | undefined;
  link: string | undefined;
  id?: number;
}

export const FileContext = createContext<AppState>({} as AppState);
