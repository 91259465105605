import { Link } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../helpers';
import { useLayout } from '../../core';

const SidebarLogo = () => {
  const { config } = useLayout();

  return (
    <div className="app-sidebar-logo h-auto d-flex pt-6 px-6" id="kt_app_sidebar_logo">
      <Link to="/company-select">
        {config.layoutType === 'dark-sidebar' ? (
          <img
            alt="Logo"
            src={toAbsoluteUrl('/media/logos/logo-light.png')}
            className="h-100px app-sidebar-logo-default theme-dark-show ms-2"
          />
        ) : (
          <>
            <img
              alt="Logo"
              src={toAbsoluteUrl('/media/logos/logo-dark.png')}
              className="h-100px app-sidebar-logo-default ms-2"
            />
          </>
        )}

        <img
          alt="Logo"
          src={toAbsoluteUrl('/media/logos/logo-dark.png')}
          className="h-30px app-sidebar-logo-minimize"
        />
      </Link>
    </div>
  );
};

export { SidebarLogo };
