/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react';
import clsx from 'clsx';
import { User, Event } from '../../../types/chats';
import { timeDifference } from 'helpers/chat/timeDifference';
import moment from 'moment';
import { toAbsoluteUrl } from '../../helpers';

type Props = {
  isDrawer?: boolean;
  customer: User | undefined;
  agentId: User | undefined;
  messages: Event[];
};
const ChatInner: FC<Props> = (props) => {
  const { isDrawer = false, customer, agentId, messages } = props;

  const messageTypeFile = (message: Event) => {
    if (message.content_type === 'image/jpeg') {
      return (
        <a href={message.url} target={'_blank'}>
          <img src={message.thumbnail_url} alt={message.name} className={'rounded'} />
        </a>
      );
    } else if (message.content_type === 'image/png') {
      return (
        <a href={message.url} target={'_blank'}>
          <img src={message.thumbnail_url} alt={message.name} className={'rounded'} />
        </a>
      );
    } else if (message.content_type === 'application/pdf') {
      return (
        <a href={message.url} target={'_blank'}>
          <div
            style={{
              // maxWidth: "70%",
              backgroundColor: message.author_id === customer?.id ? '#007DFF' : '',
              borderBottomLeftRadius: message.author_id === customer?.id ? '' : '10px',
              borderTopRightRadius: message.author_id === customer?.id ? '10px' : '',
            }}
            className={clsx(
              'p-5 fs-h3 d-flex align-items-center',
              `rounded-${message.author_id === customer?.id ? 'start-3' : 'end-3'}`,
              `bg-${message.author_id === customer?.id ? '' : 'gray-150'}`,
              `text-${message.author_id === customer?.id ? 'gray-150' : 'gray-950'}`,
              // `bg-light-${message.author_id === customer?.id ? 'info' : 'primary'}`,
              'fw-normal',
              `text-${message.author_id === customer?.id ? 'end' : 'start'}`
            )}
            data-kt-element="message-text"
          >
            <div>
              <img alt="" className="w-50px me-3" src={toAbsoluteUrl('/media/svg/files/pdf.svg')} />
            </div>
            <div>
              <a
                href={message.url}
                target={'_blank'}
                className={`text-${message.author_id === customer?.id ? 'gray-150' : 'gray-950'}`}
              >
                Open file
              </a>
            </div>
          </div>
        </a>
      );
    } else {
      return (
        <a href={message.url} target={'_blank'}>
          <div
            style={{
              // maxWidth: "70%",
              backgroundColor: message.author_id === customer?.id ? '#007DFF' : '',
              borderBottomLeftRadius: message.author_id === customer?.id ? '' : '10px',
              borderTopRightRadius: message.author_id === customer?.id ? '10px' : '',
            }}
            className={clsx(
              'p-5 fs-h3 d-flex align-items-center',
              `rounded-${message.author_id === customer?.id ? 'start-3' : 'end-3'}`,
              `bg-${message.author_id === customer?.id ? '' : 'gray-150'}`,
              `text-${message.author_id === customer?.id ? 'gray-150' : 'gray-950'}`,
              // `bg-light-${message.author_id === customer?.id ? 'info' : 'primary'}`,
              'fw-normal',
              `text-${message.author_id === customer?.id ? 'end' : 'start'}`
            )}
            data-kt-element="message-text"
          >
            <div>
              <img alt="" className="w-50px me-3" src={toAbsoluteUrl('/media/svg/files/folder-document.svg')} />
            </div>
            <div>
              <a
                href={message.url}
                target={'_blank'}
                className={`text-${message.author_id === customer?.id ? 'gray-150' : 'gray-950'}`}
              >
                Open file
              </a>
            </div>
          </div>
        </a>
      );
    }
  };

  return (
    <div
      className="card-body p-8 ps-lg-16 pt-lg-20"
      id={isDrawer ? 'kt_drawer_chat_messenger_body' : 'kt_chat_messenger_body'}
    >
      <div
        className={clsx('message-list scroll-y me-n5 pe-16')}
        data-kt-element="messages"
        data-kt-scroll="true"
        data-kt-scroll-activate="{default: false, lg: true}"
        data-kt-scroll-max-height="auto"
        data-kt-scroll-dependencies={
          isDrawer
            ? '#kt_drawer_chat_messenger_header, #kt_drawer_chat_messenger_footer'
            : '#kt_header, #kt_app_header, #kt_app_toolbar, #kt_toolbar, #kt_footer, #kt_app_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer'
        }
        data-kt-scroll-wrappers={
          isDrawer ? '#kt_drawer_chat_messenger_body' : '#kt_content, #kt_app_content, #kt_chat_messenger_body'
        }
        data-kt-scroll-offset={isDrawer ? '0px' : '5px'}
      >
        {messages.map((message, index) => {
          const contentClass = `${isDrawer ? '' : 'd-flex'} justify-content-${
            message.author_id === customer?.id ? 'end' : 'start'
          } mb-4`;
          if (message.type === 'system_message') {
            return (
              <div key={`message${index}`} className={'d-flex mb-10 justify-content-center fw-bold  text-gray-600'}>
                {message.text}
              </div>
            );
          }
          const formattedDate = moment(message.created_at).format('MMMM D, hh:mm A');
          return (
            <div key={`message${index}`} className={clsx('d-flex', 'mb-4', contentClass)}>
              <div
                style={{ maxWidth: '70%' }}
                className={clsx(
                  'd-flex flex-column align-items',
                  `align-items-${message.author_id === customer?.id ? 'end' : 'start'}`
                )}
              >
                <div className="d-flex align-items-center mb-2">
                  {message.author_id === customer?.id ? (
                    <>
                      <div className="ms-3">
                        {/* <a href="#" className="fs-h4 fw-normal font-secondary text-gray-750 text-hover-primary me-1">
                          {customer.name},
                        </a> */}
                        <span className="fs-h4 fw-normal font-secondary text-gray-750 text-hover-primary me-1">
                          {formattedDate}
                        </span>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="me-3">
                        {/*<span className="text-muted fs-7 mb-1">{timeDifference(message.created_at)}</span>*/}
                        <a
                          href="#"
                          className="fs-h4 fw-normal font-secondary text-hover-primary ms-1"
                          style={{ color: '#144AD7' }}
                        >
                          {agentId?.name}
                        </a>
                      </div>
                    </>
                  )}
                </div>

                {message.elements && (
                  <div
                    className="p-5 rounded-end-3 bg-gray-150 text-gray-950 fw-normal text-center fs-h3"
                    style={{ borderBottomLeftRadius: '10px' }}
                  >
                    <div>{message.elements[0].title}</div>
                    {message.elements[0].subtitle && <div>{message.elements[0].subtitle}</div>}
                    {message.elements[0].buttons && (
                      <div className="text-gray-600">
                        <hr />
                        {message.elements[0].buttons.map((button, index) => (
                          <div key={`chat-button-${button.text}-${index}`}>
                            {button.text}
                            <hr />
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                )}

                {message.text && (
                  <div
                    style={{
                      // maxWidth: "70%",
                      backgroundColor: message.author_id === customer?.id ? '#007DFF' : '',
                      borderBottomLeftRadius: message.author_id === customer?.id ? '' : '10px',
                      borderTopRightRadius: message.author_id === customer?.id ? '10px' : '',
                    }}
                    className={clsx(
                      'p-5 fs-h3',
                      `rounded-${message.author_id === customer?.id ? 'start-3' : 'end-3'}`,
                      `bg-${message.author_id === customer?.id ? '' : 'gray-150'}`,
                      `text-${message.author_id === customer?.id ? 'gray-150' : 'gray-950'}`,
                      // `bg-light-${message.author_id === customer?.id ? 'info' : 'primary'}`,
                      'fw-normal',
                      `text-${message.author_id === customer?.id ? 'end' : 'start'}`
                    )}
                    data-kt-element="message-text"
                    dangerouslySetInnerHTML={{ __html: message.text }}
                  ></div>
                )}

                {message.type === 'file' && <div>{messageTypeFile(message)}</div>}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export { ChatInner };
