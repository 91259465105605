import { GlobalItemsList } from './GlobalItemsList';
import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import useSettingBrands from '../../../../hooks/SettingHooks/useSettingBrands';
import useSettingServices from '../../../../hooks/SettingHooks/useSettingServices';
import { Col, Row } from "react-bootstrap";

type BrandsAndServicesProps = {};

export const filterOptions = ({ data }) => data.reduce((res, option) => {
  const type = option.type || "solar";
  return {
    ...res,
    [type]: res[type] ? [...res[type], option] : [option]
  }
}, {});

const BrandsAndServicesTab: FC<BrandsAndServicesProps> = () => {
  const [servicesOnDelete, setServicesOnDelete] = useState<number[]>([]);
  const [brandsOnDelete, setBrandsOnDelete] = useState<number[]>([]);

  const { brands, refetch: refetchBrands, isLoading: isLoadingBrands, error: errorBrands } = useSettingBrands();
  const {
    services,
    refetch: refetchServices,
    isLoading: isLoadingServices,
    error: errorServices,
  } = useSettingServices();

  const filterBrands = filterOptions({ data: brands })
  const filterServices = filterOptions({ data: services })

  const deleteBrands = async (setIsLoading: Dispatch<SetStateAction<boolean>>) => {
    setIsLoading(true);
    try {
      const response = await axios.delete(`${process.env.REACT_APP_API_URL}/brands/global`, {
        data: {
          brandsOnDelete: brandsOnDelete,
        },
      });
      toast.success(`Success delete ${response.data.count} brands`);
      refetchBrands();
    } catch (error) {
      toast.error(`Error: ${error}`);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteServices = async (setIsLoading: Dispatch<SetStateAction<boolean>>) => {
    setIsLoading(true);
    try {
      const response = await axios.delete(`${process.env.REACT_APP_API_URL}/services/global`, {
        data: {
          servicesOnDelete: servicesOnDelete,
        },
      });
      toast.success(`Success delete ${response.data.count} services`);
      refetchServices();
    } catch (error) {
      toast.error(`Error: ${error}`);
    } finally {
      setIsLoading(false);
    }
  };

  const createBrand = ({ type }: {type: "aircon" | "solar"}) => async (setIsLoading: Dispatch<SetStateAction<boolean>>, newItemName: string) => {
    setIsLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/brands/global`, {
        name: newItemName,
        type
      });
      toast.success(`Success create global Brand with name ${response.data.name}`);
      refetchBrands();
    } catch (error) {
      toast.error(`Error: ${error}`);
    } finally {
      setIsLoading(false);
    }
  };

  const createService = ({ type }: {type: "aircon" | "solar"}) => async (setIsLoading: Dispatch<SetStateAction<boolean>>, newItemName: string) => {
    setIsLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/services/global`, {
        name: newItemName,
        type
      });
      toast.success(`Success create global Service with name ${response.data.name}`);
      refetchServices();
    } catch (error) {
      toast.error(`Error: ${error}`);
    } finally {
      setIsLoading(false);
    }
  };

  const configBrand = {
    items: filterBrands,
    setItemsOnDelete: setBrandsOnDelete,
    itemsOnDelete: brandsOnDelete,
    createItem: createBrand,
    deleteItems: deleteBrands,
    label: "Create Brand",
  }

  const configService = {
    items: filterServices,
    setItemsOnDelete: setServicesOnDelete,
    itemsOnDelete: servicesOnDelete,
    createItem: createService,
    deleteItems: deleteServices,
    label: "Create Services",
  }
  const isTypeBrand = brands.some((item) => item.type)
  const isTypeService = services.some((item) => item.type)


  const config = [
    {
      ...configBrand,
      type: "solar",
      title: isTypeBrand ? "Solar Brands" : "Global Brands",
      inputPlaceholder: isTypeBrand ? "New Solar Brand Name..." : "New Global Brand Name...",
    },
    {
      ...configBrand,
      type: "aircon",
      title: "Air con Brands",
      inputPlaceholder: "New Air con Brand Name..."
    },
    {
      ...configService,
      type: "solar",
      title: isTypeService ? "Solar Services" : "Global Services",
      inputPlaceholder: isTypeService ? "New Solar Services Name..." : "New Global Services Name..."
    },
    {
      ...configService,
      type: "aircon",
      title: "Air con Services",
      inputPlaceholder: "New Air con Services Name..."
    },
  ];

  return !isLoadingBrands || !isLoadingServices ? (
      <Row className="pb-10">
        {config.map((option) => {
          const { items, type } = option;
          return (
              <Col sm={12} lg={6}>
                <div className="mx-5 mb-8">
                  {/*@ts-ignore*/}
                  <GlobalItemsList {...option} />
                </div>
              </Col>
          )
        })}
      </Row>
  ) : (
    <div className="d-flex justify-content-center mt-20">
      <span className="mx-4">
        <h1>Loading</h1>
      </span>
      <span className="spinner-border spinner-border align-middle ms-2"></span>
    </div>
  );
};

export default BrandsAndServicesTab;
