export const idArrayControl = (id: number, idArray: number[]): number[] => {
  const deleteArray = [...idArray];

  if (deleteArray.includes(id)) {
    let index = deleteArray.indexOf(id);
    if (index > -1) {
      deleteArray.splice(index, 1);
    }
  } else {
    deleteArray.push(id);
  }

  return deleteArray;
};

export const stringArrayControl = (id: string, stringArray: string[]): string[] => {
  const deleteArray = [...stringArray];

  if (deleteArray.includes(id)) {
    let index = deleteArray.indexOf(id);
    if (index > -1) {
      deleteArray.splice(index, 1);
    }
  } else {
    deleteArray.push(id);
  }

  return deleteArray;
};
