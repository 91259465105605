import {
  arc,
  ascending,
  easeBackOut,
  format,
  interpolate,
  merge,
  pie,
  PieArcDatum,
  scaleOrdinal,
  select,
  Selection as D3Selection,
  sum,
} from 'd3';
import React, { useEffect, useRef, useState } from 'react';
import { COLOR } from '../constants';
import { Tooltip } from './tooltip';

type D3SvgElement = D3Selection<SVGSVGElement, unknown, null, undefined>;
type Data = { name: string; value: number };

function updateChart(
  svg: D3SvgElement,
  data: { name: string; value: number }[],
  width: number,
  height: number,
  isDonut: boolean,
  colors: string[]
) {
  data = data.sort((a, b) => ascending(a.name, b.name));
  let margin = isDonut ? 0 : 50;

  let displaySize = height;
  let outerRadiusIndex = 1.2;
  let padAngle = 10;
  let padRadius = 20;
  let pieGeoPadAngleDonut = 0.5;

  if (height * 2.2 > width) {
    displaySize = height - 70;
  }

  if (width < 1200) {
    pieGeoPadAngleDonut = 0.6;
  }

  if (width < 450) {
    displaySize = 230;
    pieGeoPadAngleDonut = 0.7;
    outerRadiusIndex = 1.25;
    padAngle = 12;
    padRadius = 24;
  }

  let radius = isDonut ? Math.min(width, displaySize) / 2.3 : Math.min(width, displaySize) / 2 - margin;
  const innerSize = isDonut ? radius * 0.6 : 0;
  const outerSize = radius / outerRadiusIndex;

  let pieGeo = pie<Data>()
    .padAngle(isDonut ? pieGeoPadAngleDonut : 0)
    .value((d) => d.value);
  let arcGeo = arc<PieArcDatum<Data>>()
    .innerRadius(innerSize)
    .outerRadius(outerSize)
    .cornerRadius(() => {
      return (outerSize - innerSize) / 2;
    })
    .padAngle(padAngle)
    .padRadius(padRadius);
  let arcGeoHover = arc<PieArcDatum<Data>>();
  // .innerRadius(isDonut ? radius * 0.6 : 0)
  // .outerRadius(radius * 1.0075)

  let color = scaleOrdinal(colors).domain(data.map((d) => d.name));

  const mergeWithFirstEqualZero = (first: Data[], second: Data[]) => {
    var secondSet = new Set();

    second.forEach((d) => secondSet.add(d.name));

    let onlyFirst = first.filter((d) => !secondSet.has(d.name)).map((d) => ({ name: d.name, value: 0 }));

    let sortedMerge = merge<Data>([second, onlyFirst]).sort((a, b) => ascending(a.name, b.name));

    return sortedMerge;
  };

  let oldData = svg
    .select('#chart')
    .selectAll('path')
    .data()
    .map((d) => (d as { data: Data }).data);

  let was = mergeWithFirstEqualZero(data, oldData);
  let is = mergeWithFirstEqualZero(oldData, data);

  let legendWidth = outerSize * 2;
  let freeSpace = width - outerSize * 2 - legendWidth;

  if (freeSpace < 0) {
    legendWidth = legendWidth + freeSpace - 24;
    freeSpace = 24;
  }

  const part = freeSpace / 3;
  let chartSpace = width - outerSize - part;

  if (width / 2 < outerSize * 2) {
    chartSpace = width - outerSize;
  }

  svg
    .attr('preserveAspectRatio', 'xMidYMid meet')
    .attr('height', '100%')
    .attr('width', '100%')
    .attr('viewBox', `0 0 ${width} ${height}`)
    .select('#chart')
    .attr('transform', `translate(${chartSpace}, ${height / 2})`)
    .selectAll('path')
    .data(pieGeo(was))
    .join(
      (enter) =>
        enter
          .insert('path')
          .attr('fill', (d) => color(d.data.name))
          .each(function (this, d) {
            // @ts-ignore
            this._current = d;
          })
          .data(pieGeo(is))
          .call((enter) =>
            enter
              .transition()
              .duration(750)
              .attrTween('d', function (this, d) {
                // @ts-ignore
                let interpolation = interpolate(this._current, d);
                let _this = this;
                return (t) => {
                  // @ts-ignore
                  _this._current = interpolation(t);
                  // @ts-ignore
                  return arcGeo(_this._current) || '';
                };
              })
          )
          .data(pieGeo(data)),
      (update) =>
        update
          .data(pieGeo(is))
          .call((enter) =>
            enter
              .transition()
              .duration(750)
              .attrTween('d', function (this, d) {
                // @ts-ignore
                let interpolation = interpolate(this._current, d);
                let _this = this;
                return (t) => {
                  // @ts-ignore
                  _this._current = interpolation(t);
                  // @ts-ignore
                  return arcGeo(_this._current) || '';
                };
              })
          )
          .data(pieGeo(data)),
      (exit) => exit.transition().delay(750).duration(0).remove()
    )
    .on('mouseover', function (_, d) {
      select(this).transition().duration(250).ease(easeBackOut.overshoot(5));
      // @ts-ignore
      // .attr("d", arcGeoHover);

      svg
        .select('#legend')
        .selectAll('rect')
        .style('opacity', (d2) => (d.data === d2 ? 1 : 0.4));

      svg
        .select('#legend')
        .selectAll('text')
        .style('opacity', (d2) => (d.data === d2 ? 1 : 0.4));

      svg
        .select('#chart')
        .selectAll('path')
        .style('opacity', (d2) => (d.data === (d2 as { data: Data }).data ? 1 : 0.4));
    })
    .on('mouseout', function (d) {
      // @ts-ignore
      select(this).transition().duration(250).attr('d', arcGeo);

      svg.select('#legend').selectAll('rect').style('opacity', 1);

      svg.select('#legend').selectAll('text').style('opacity', 1);

      svg.select('#chart').selectAll('path').style('opacity', 1);
    });

  let total = sum(data.map((d) => d.value));
  const pointSize = 14;

  let percentSpaceX = legendWidth - pointSize * 2;
  let percentSpaceY = isDonut ? 95 : 95 + radius * 2;

  if (legendWidth < outerSize * 2) {
    percentSpaceX = pointSize * 2;
    percentSpaceY = isDonut ? 118 : 95 + radius * 2;
  }

  svg
    .select('#legend')
    .attr('transform', `translate(0, ${height / 1.6})`)
    .selectAll('rect')
    .data(data)
    .join('rect')
    .attr('y', (d, i) => -height / 2 + 50 * i + (isDonut ? 80 : 80 + radius * 2))
    .attr('width', pointSize)
    .attr('height', pointSize)
    .attr('fill', (d) => color(d.name))
    .attr('rx', pointSize / 2);

  svg
    .select('#legend')
    .selectAll('text[class=percent]')
    .data(data)
    .join('text')
    .attr('class', 'percent')
    .attr('text-anchor', 'start')
    .attr('x', percentSpaceX)
    .attr('y', (d, i) => -height / 2 + 50 * i + percentSpaceY)
    .text((d) => `${format('.0%')(d.value / total)}`);

  svg
    .select('#legend')
    .selectAll('text[class=label]')
    .data(data)
    .join('text')
    .attr('class', 'label')
    .attr('text-anchor', 'start')
    .attr('x', pointSize * 2)
    .attr('y', (d, i) => -height / 2 + 50 * i + (isDonut ? 95 : 95 + radius * 2))
    .text((d) => d.name);
}

export function PieChart(props: {
  header: string;
  data: [string, number][];
  tooltip: string;
  // width: number;
  height: number;
  colors: string[];
  isDonut?: boolean;
}) {
  let ref = useRef(null);
  let svgRef = useRef<SVGSVGElement>();

  const [width, setWidth] = useState(0);

  useEffect(() => {
    if (ref.current) {
      // @ts-ignore
      setWidth(ref.current.offsetWidth);
    }
  }, [ref.current]);

  useEffect(() => {
    if (svgRef.current === undefined) {
      return;
    }
    let data = props.data.map(([name, value]) => ({ name, value }));
    const svg = select(svgRef.current);
    updateChart(svg, data, width, props.height, props.isDonut || false, props.colors);
  }, [props.data, props.height, props.colors, props.isDonut, width]);

  if (!props?.data?.length) {
    return (
      <div>
        <h2
          style={{
            marginLeft: '1rem',
            fontSize: '18px',
            fontWeight: 500,
            cursor: 'help',
          }}
        >
          {props.header}
        </h2>
        <p style={{ padding: '1rem' }}>
          👋 We need more visitors and tracking data to generate these results, so please check back here again soon. 🙂
        </p>
      </div>
    );
  }

  return (
    <div ref={ref} className="d-flex align-items-center" style={{ width: '100%', height: props.height }}>
      <svg
        className="d3-pie-chart"
        ref={(ref: SVGSVGElement) => (svgRef.current = ref)}
        viewBox={`0 0 ${props.height} ${props.height}`}
      >
        <Tooltip
          trigger={
            <text
              id="title"
              // x={-props.width / 2 + 30}
              y={-props.height / 2 + 30}
              fontWeight="500"
              fontSize="18px"
              fill={COLOR.black1}
              cursor="help"
            >
              {props.header}
            </text>
          }
        >
          {props.tooltip}
        </Tooltip>
        <g id="legend"></g>
        <g id="chart"></g>
      </svg>
    </div>
  );
}
