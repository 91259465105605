import axios from 'axios';
import { useQuery } from 'react-query';
import { Company } from 'types/main';

const fetchCompanies = (companyId: number): Promise<Company> => {
  // TODO move fetch to API helper

  return axios.get(`${process.env.REACT_APP_API_URL}/companies/${companyId}`).then((response) => {
    return response.data;
  });
};

export const useCompany = (companyId: number) => {
  const { isLoading, data, refetch, isFetching } = useQuery({
    queryKey: ['company', companyId],
    queryFn: () => fetchCompanies(companyId),
    onError: () => {
      // TODO show notifications
    },
  });
  const company = data;

  return {
    isFetching,
    refetch,
    company,
    isLoading,
  };
};
