// @ts-nocheck
import Popup from 'reactjs-popup';
import { PopupProps } from 'reactjs-popup/dist/types';
import { COLOR } from '../constants';
import 'reactjs-popup/dist/index.css';

export function Tooltip(props: PopupProps) {
  const { children, ...popupProps } = props;
  popupProps.position = popupProps.position || 'bottom center';

  return (
    <Popup {...popupProps}>
      {(close: () => void) => (
        <div className="popup-main">
          {children}
          <button className="popup-close" onClick={close} style={{ background: COLOR.white, borderColor: COLOR.blue }}>
            Got it
          </button>
        </div>
      )}
    </Popup>
  );
}
