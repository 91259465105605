import { useQuery } from 'react-query';
import axios from 'axios';
import { Service } from '../../types/main';
const API_URL = process.env.REACT_APP_API_URL;

const useSettingServices = () => {
  const { isLoading, error, data, refetch } = useQuery({
    queryKey: ['services'],
    queryFn: async (): Promise<Service[]> => {
      const response = await axios.get(`${API_URL}/services/global`);
      return response.data;
    },
  });

  return {
    isLoading,
    error,
    services: data || [],
    refetch,
  };
};

export default useSettingServices;
