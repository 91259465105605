import { Company } from '../../../../types/main';
import clsx from 'clsx';

type HeaderUserInfoProps = {
  companyName?: string;
  user?: string;
  className?: string;
};
const HeaderUserInfo = (props: HeaderUserInfoProps) => {
  const { companyName, user, className } = props;
  return (
    <div className={clsx(className)}>
      <p className="m-0 fs-h2">{companyName}</p>
      <p className="m-0 fs-h4 font-secondary text-gray-700">{user}</p>
    </div>
  );
};
export default HeaderUserInfo;
