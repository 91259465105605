import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import React from 'react';

export type ChartProps = {
  data?: [];
  values?: [];
  categories?: [];
  width?: string;
  height?: string;
  label?: string;
  strokeColor?: string;
  strokeWidth?: number;
  colors?: string[];
  extraOptions?: {
    xaxis?: {};
    yaxis?: {};
    grid?: {};
    chart?: {};
    fill?: {
      gradient?: {};
    };
    tooltip?: {};
  };
  getPointSelection?: (index: number) => void
};
const AreaChart = ({ data = [], height, width, label, strokeWidth = 3, strokeColor, extraOptions }: ChartProps) => {
  const displayData = data.map(({ value }) => value);
  const displayX = data?.map(({ date }) => date);
  const options: ApexOptions = {
    chart: {
      height,
      width,
      type: 'area',
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: true,
        tools: {
          download: false,
        },
      },
      ...extraOptions?.chart,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'gradient',
      gradient: {
        type: 'vertical',
        shadeIntensity: 0.5,
        gradientToColors: [`${strokeColor}`],
        inverseColors: true,
        opacityFrom: 0.75,
        opacityTo: 0,
        stops: [60, 100],
        colorStops: [],
        ...extraOptions?.fill?.gradient,
      },
    },
    stroke: {
      curve: 'smooth',
      width: strokeWidth,
      ...(strokeColor ? { colors: [strokeColor] } : {}),
    },
    xaxis: {
      type: 'datetime',
      categories: displayX,
      tickPlacement: 'on',
      labels: {
        datetimeFormatter: {
          year: 'yyyy',
          month: 'MMMM',
          day: 'MMM dd',
          hour: 'HH:mm',
        },
      },
      tooltip: {
        enabled: false,
      },
      ...extraOptions?.xaxis,
    },
    yaxis: {
      ...extraOptions?.yaxis,
    },
    tooltip: {
      enabled: true,
      style: {
        fontSize: '13px',
      },
      fillSeriesColor: false,
      x: {
        show: true,
        format: 'dd/MM/yy',
      },
      marker: {
        show: false,
      },
      fixed: {
        enabled: false,
        position: 'topRight',
        offsetX: 0,
        offsetY: 0,
      },
      ...extraOptions?.tooltip,
    },
    colors: [strokeColor],
    grid: {
      ...extraOptions?.grid,
    },
  };

  const series = [
    {
      name: label || 'Value',
      data: displayData,
    },
  ];
  return (
    <div className="d-flex align-items-center area-chart flex-grow-1 mb-xl-n10">
      <div className="w-100">
        <Chart
          options={options}
          series={series}
          type="area"
          // width={width || "500"}
          height={height || '500'}
        />
      </div>
    </div>
  );
};

export default AreaChart;
