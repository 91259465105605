import { Dispatch, FC, SetStateAction, useState } from 'react';
import clsx from 'clsx';
import { idArrayControl } from '../../../../helpers/idArrayControl';
import { Button, Col, Row } from 'react-bootstrap';

type CreateItemFunction = ({ type }: {type: "aircon" | "solar"}) => (setIsLoading: Dispatch<SetStateAction<boolean>>, newBrandName: string) => Promise<void>;

type DeleteItemsFunction = (setIsLoading: Dispatch<SetStateAction<boolean>>) => Promise<void>;

type Item = {
  id: number;
  name: string;
  isGlobal: boolean;
}

type GlobalItemsListProps = {
  items: { solar?: Item[]; aircon?: Item[] };
  setItemsOnDelete: Dispatch<SetStateAction<number[]>>;
  itemsOnDelete: number[];
  title: string;
  createItem: CreateItemFunction;
  deleteItems: DeleteItemsFunction;
  label: string;
  inputPlaceholder: string;
  type: "aircon" | "solar";
};

const GlobalItemsList: FC<GlobalItemsListProps> = ({
  items,
  setItemsOnDelete,
  itemsOnDelete,
  title,
  createItem,
  deleteItems,
  label,
  inputPlaceholder,
  type
}) => {
  const [newItemName, setNewItemName] = useState<string>('');
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  return (
    <div className="container h-100 d-flex row">
      <h2 className="text-center">{title}</h2>
      {/*<div>*/}
      {/*  {items[type]?.map((item, index) => (*/}
      {/*    <div key={`${item.name}-${index}`} className="d-flex justify-content-center align-items-center">*/}
      {/*      <div*/}
      {/*        className={clsx(*/}
      {/*          'my-3 py-3 cursor-pointer w-50 text-center rounded text-dark fw-bold mb-1 fs-6',*/}
      {/*          itemsOnDelete.includes(item.id) ? 'border border-2 border-danger' : 'border border-2 border-white'*/}
      {/*        )}*/}
      {/*        key={`${item.name}-list-item`}*/}
      {/*        onClick={() => setItemsOnDelete(idArrayControl(item.id, itemsOnDelete))}*/}
      {/*      >*/}
      {/*        {item.name}*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  ))}*/}
      {/*</div>*/}
      <div className="d-flex flex-wrap gap-4">
        {items[type]?.map((item, index) => (
            <div key={`${item.name}-${index}`} className="d-flex justify-content-center align-items-center">
              <p
                  className={clsx(
                      'my-3 py-3 px-5 cursor-pointer text-center rounded text-dark fw-bold mb-1 fs-6 text-nowrap',
                      itemsOnDelete.includes(item.id) ? 'border border-2 border-danger' : 'border border-2 border-gray'
                  )}
                  key={`${item.name}-list-item`}
                  onClick={() => setItemsOnDelete(idArrayControl(item.id, itemsOnDelete))}
              >
                {item.name}
              </p>
            </div>
        ))}
      </div>
      <div className={'d-flex align-items-end my-5'}>
        <div className={'d-flex row justify-content-center my-5'}>
          <Row className="justify-content-center">
            <Col md={6} className="position-relative mt-6 mb-9 position-relative ">
              <input
                type="text"
                className={'form-control rounded-3 fs-base'}
                value={newItemName}
                onChange={(e: any) => setNewItemName(e.target.value)}
                placeholder={inputPlaceholder}
              />
              <label className="position-absolute top-0 start-30px translate-middle-y m-0 px-2 form-label fs-base fw-normal bg-white text-capitalize">
                {label}
              </label>
            </Col>
            <Col md={2} className="mt-md-6 mb-9">
              <Button
                variant="dark"
                className="ms-md-9 text-nowrap"
                disabled={newItemName === ''}
                onClick={() => {
                  createItem({ type })(setIsLoading, newItemName);
                  setNewItemName('');
                }}
              >
                {label}
                {isLoading && <span className="spinner-border spinner-border-sm align-middle ms-2"></span>}
              </Button>
            </Col>
          </Row>
          <div className={'d-flex justify-content-center my-3'}>
            <Button
              size="lg"
              variant="dark"
              type="submit"
              className="m-0"
              disabled={itemsOnDelete.length === 0}
              onClick={() => {
                deleteItems(setIsDeleting);
                setItemsOnDelete([]);
              }}
            >
              Delete selected
              {isDeleting && <span className="spinner-border spinner-border-sm align-middle ms-2"></span>}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export { GlobalItemsList };
