import React from 'react';
import { Dropdown } from 'react-bootstrap';
import HeaderUserInfo from '../../../layout/components/header/HeaderUserInfo';
import clsx from 'clsx';
import { toAbsoluteUrl } from '../../../helpers';
import { useAuth } from '../../../../app/modules/auth';
import SVG from 'react-inlinesvg';
import { Link } from 'react-router-dom';

const menuOptions = [
  { name: 'Settings', icon: '/media/menu/Settings.svg', href: '/settings' },
  { name: 'Billing', icon: '/media/menu/credit-card.svg', href: '/settings/billing' },
  { name: 'Log Out', icon: '/media/menu/box-arrow-right.svg', href: '' },
];

type HeaderUserProps = {
  userAvatarClass: string;
  logoUrl: string;
  companyName?: string;
};
const HeaderUser = (props: HeaderUserProps) => {
  const { userAvatarClass, logoUrl, companyName } = props;
  const { currentUser, logout } = useAuth();

  return (
    <Dropdown>
      <Dropdown.Toggle variant="link" id="dropdown-basic" bsPrefix="p-0" className="d-flex me-4">
        <div
          className={clsx(
            'cursor-pointer symbol symbol-md-50px  rounded-circle bg-white border border-gray-250 overflow-hidden',
            userAvatarClass
          )}
        >
          <img className="img-fluid" src={logoUrl ? logoUrl : toAbsoluteUrl('/media/avatars/blank.png')} alt="" />
        </div>
      </Dropdown.Toggle>

      <Dropdown.Menu className="mt-2 p-3 p-md-5 border border-gray-250 rounded-3">
        <Dropdown.Item className="d-fex d-md-none">
          <HeaderUserInfo user={currentUser?.email} companyName={companyName} />
        </Dropdown.Item>

        {menuOptions.map((item) => {
          const { href, name, icon } = item;
          return href ? (
            <Dropdown.Item key={name}>
              <Link to={href} className="d-flex align-items-center text-black">
                <SVG src={toAbsoluteUrl(icon)} height={15} width={15}/>
                <p className="ms-2 mb-0 fs-h4 font-secondary fw-normal">{name}</p>
              </Link>
            </Dropdown.Item>
          ) : (
            <Dropdown.Item key={name} onClick={logout} className="d-flex align-items-center">
              <SVG src={toAbsoluteUrl(icon)} />
              <p className="ms-2 mb-0 fs-h4 font-secondary fw-normal">{name}</p>
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default HeaderUser;
