import React, { FC } from 'react';
import BrandsAndServicesTab from './components/BrandsAndServicesTab';
import { ToastContainer } from 'react-toastify';

type GlobalSettingPageProps = {};

const GlobalSettingPage: FC<GlobalSettingPageProps> = () => {
  return (
    <div className="mx-14 mt-20 px-10">
      <BrandsAndServicesTab />

      <ToastContainer position="top-right" autoClose={3000} hideProgressBar />
    </div>
  );
};

export default GlobalSettingPage;
