/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { KTSVG } from '../../../_metronic/helpers';
import { CompanyTableElem } from './CompanyTableElem';
import { useCompanies } from '../../../hooks/useCompanies';
import { CompaniesTableHeader } from './CompaniesTableHeader';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { USER_ROLES } from "../../../helpers/utils";

type CompaniesTableProps = {
  className: string;
  isModal: boolean;
  closeModal?: Dispatch<SetStateAction<boolean>>;
};

type optionsType = {
  [key: string]: boolean | null;
};

const options: optionsType = {
  Unclaimed: null,
  Subscribed: true,
  Unsubscribed: false,
};

const CompaniesTable: React.FC<CompaniesTableProps> = ({ className, isModal, closeModal }) => {
  const isSuperAdmin = window.localStorage.getItem('userRole') === USER_ROLES.superadmin;
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedSubscription, setSelectedSubscription] = useState<string>("");

  const { companies, count, isLoading, refetch } = useCompanies(currentPage, searchTerm, selectedSubscription);

  const optionsKeys = Object.keys(options);

  useEffect(() => {
    const subscription = isSuperAdmin ? '' : 'Unclaimed';
    setSelectedSubscription(subscription);
  }, [isSuperAdmin]);

  return (
    <div className={`card ${className}`}>
      <CompaniesTableHeader
        count={count}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        optionsKeys={optionsKeys}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        setSelectedSubscription={setSelectedSubscription}
        isLoading={isLoading}
        refetch={refetch}
      />
      {/* begin::Body */}
      <div className="card-body py-3">
        {/* begin::Table container */}
        <div className="table-responsive">
          {/* begin::Table */}
          <table className="table align-middle gs-0 gy-5">
            {/* begin::Table head */}
            <thead>
              <tr>
                <th className="p-0 w-50px"></th>
                <th className="p-0 min-w-150px"></th>
                <th className="p-0 min-w-150px"></th>
                <th className="p-0 min-w-125px"></th>
                <th className="p-0 min-w-40px"></th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            {isLoading ? (
              <tbody>
                <tr>
                  <td>
                    <h1>Loading...</h1>
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                {companies?.map((company) => {
                  return (
                    !company.deleted && (
                      <CompanyTableElem
                        key={`company-${company.id}`}
                        company={company}
                        isModal={isModal}
                        closeModal={closeModal}
                      />
                    )
                  );
                })}
              </tbody>
            )}
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* end::Body */}
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar />
    </div>
  );
};

export { CompaniesTable };
