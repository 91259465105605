export interface Range {
  startDate: Date;
  endDate: Date;
  key?: string;
}

interface RangeData {
  axis: string[];
  values: number[];
}

interface CategoricalData {
  total: number;
  counts: [string, number][];
  percentages: [string, number][];
}

export interface AnalyticsData {
  visitors: RangeData;
  time_on_page: RangeData;
  clicks: RangeData;
  impressions: RangeData;
  userType: CategoricalData;
  userGender: CategoricalData;
  deviceCategory: CategoricalData;
  userAgeBracket: CategoricalData;
  region: CategoricalData;
}

export const initAnalyticsData = {
  visitors: { axis: [], values: [] },
  time_on_page: { axis: [], values: [] },
  clicks: { axis: [], values: [] },
  impressions: { axis: [], values: [] },
  userType: { total: 0, counts: [], percentages: [] },
  userGender: { total: 0, counts: [], percentages: [] },
  deviceCategory: { total: 0, counts: [], percentages: [] },
  userAgeBracket: { total: 0, counts: [], percentages: [] },
  region: { total: 0, counts: [], percentages: [] },
};

export interface APIAnalyticsData {
  uuid: number;
  name: string;
  url_prefix: string;
  created: string;
  updated: string;
  retrieved: string;
  analytics: AnalyticsData;
}

export const initAPIAnalyticsData = {
  uuid: 0,
  name: '',
  url_prefix: '',
  created: '',
  updated: '',
  retrieved: '',
  analytics: initAnalyticsData,
};
