import axios from 'axios';
import { AuthModel, UserModel } from './_models';
import { ProviderHoursData, UpdateCompanyBrandsRequest, UpdateCompanyServiceRequest } from 'types/main';

const API_URL = process.env.REACT_APP_API_URL;

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`;
export const GET_USER_BY_COGNITO_ID = `${API_URL}/get_by_cognito_id`;
export const LOGIN_URL = `${API_URL}/login`;
export const REGISTER_URL = `${API_URL}/register`;
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`;
export const UPDATE_COMPANY_URL = `${API_URL}/companies`;
export const UPDATE_COMPANY_CATEGORY = `${API_URL}/dashboard-select/companies/category-upd`;
export const UPDATE_PROVIDER_HOURS_URL = `${API_URL}/dashboard-select/companies/provider-hours`;
export const UPDATE_COMPANY_SERVICES = `${API_URL}/dashboard-select/service-select`;
export const UPDATE_COMPANY_BRANDS = `${API_URL}/dashboard-select/brand-select`;
export const UPDATE_COMPANY_VIDEOS = `${API_URL}/dashboard-select/update-videos`;
export const GET_ANALYTICS_DATA = `${API_URL}/analytics`;

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    email,
    password,
  });
}

// Server should return AuthModel
export async function register(
  email: string,
  cognitoID: string,
  isSuperAdmin: boolean,
  firstname: string,
  lastname: string
) {
  return await axios.post(REGISTER_URL, {
    email,
    cognitoID,
    isSuperAdmin,
    firstname,
    lastname,
  });
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, {
    email,
  });
}

export function getUserByCognitoId(cognitoID: string) {
  return axios.get(GET_USER_BY_COGNITO_ID, {
    params: { cognitoID: cognitoID },
  });
}

export function updateCompany(formData: any) {
  return axios
    .put(UPDATE_COMPANY_URL, formData)
    .then((response) => {})
    .catch((error) => {
      console.log(error);
    });
}

export async function updateCompanyCategory(categoryIds: number[], companyId: number) {
  try {
    return await axios.put(UPDATE_COMPANY_CATEGORY, { categoryIds, companyId });
  } catch (error) {
    console.error(error);
  }
}

export async function updateProviderHours(formData: ProviderHoursData) {
  try {
    axios.post(UPDATE_PROVIDER_HOURS_URL, formData);
  } catch (error) {
    console.error(error);
  }
}

export async function selectService(formData: UpdateCompanyServiceRequest) {
  try {
    axios.post(UPDATE_COMPANY_SERVICES, formData);
  } catch (error) {
    console.error(error);
  }
}

export async function selectBrands(formData: UpdateCompanyBrandsRequest) {
  try {
    axios.post(UPDATE_COMPANY_BRANDS, formData);
  } catch (error) {
    console.error(error);
  }
}
