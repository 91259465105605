import { FC, useEffect, useState } from 'react';
import { GoogleMap, useJsApiLoader, MarkerF } from '@react-google-maps/api';
import { snazzyMapStyles } from './snazzyConfig';

const CompanyMarker: FC<any> = (props) => {
  const { position, opts, color = '#000' } = props;

  return (
    <MarkerF
      //@ts-expect-error
      icon={{
        scale: 2,
        fillColor: color,
        fillOpacity: opts,
        strokeWeight: 0,
        scaledSize: 2,
        path: 'M5 14.2857C5 14.2857 10 9.20893 10 5.35714C10 3.93634 9.47322 2.57373 8.53553 1.56907C7.59785 0.564412 6.32608 0 5 0C3.67392 0 2.40215 0.564412 1.46447 1.56907C0.526784 2.57373 1.97602e-08 3.93634 0 5.35714C0 9.20893 5 14.2857 5 14.2857ZM5 8.03571C4.33696 8.03571 3.70107 7.75351 3.23223 7.25118C2.76339 6.74885 2.5 6.06754 2.5 5.35714C2.5 4.64674 2.76339 3.96544 3.23223 3.46311C3.70107 2.96078 4.33696 2.67857 5 2.67857C5.66304 2.67857 6.29893 2.96078 6.76777 3.46311C7.23661 3.96544 7.5 4.64674 7.5 5.35714C7.5 6.06754 7.23661 6.74885 6.76777 7.25118C6.29893 7.75351 5.66304 8.03571 5 8.03571Z',
      }}
      position={position}
    ></MarkerF>
  );
};

const Map: FC<any> = (props) => {
  const { containerStyle, zoom, centerPosition } = props;
  const [center, setCenter] = useState<any>(centerPosition);

  useEffect(() => {
    setCenter(centerPosition);
  }, [centerPosition]);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY as string,
  });

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={zoom}
      options={{
        styles: snazzyMapStyles,
        mapTypeControl: false,
      }}
    >
      <CompanyMarker position={centerPosition} opts={1} color="#181B32" isCentralMarker={false} />
    </GoogleMap>
  ) : (
    <></>
  );
};

export default Map;
