import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import React from 'react';
import { ChartProps } from './AreaChart';

const BarChart = ({ height, width, strokeColor, extraOptions, values = [], categories, getPointSelection }: ChartProps) => {
  const options: ApexOptions = {
    chart: {
      type: 'bar',
      width,
      height,
      toolbar: {
        show: true,
        tools: {
          download: false,
        },
      },
      events: {
        dataPointSelection: function(event, chartContext, config) {
          getPointSelection && getPointSelection(config.dataPointIndex)
        }
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 5,
        columnWidth: 20,
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories,
      ...extraOptions?.xaxis,
    },
    yaxis: {
      ...extraOptions?.yaxis,
    },
    colors: [strokeColor],
    grid: {
      ...extraOptions?.grid,
    },
    tooltip: {
      ...extraOptions?.tooltip,
    },
  };

  const series = [
    {
      name: 'Number of users',
      data: values,
    },
  ];
  return (
    <div className="">
      <Chart
        options={options}
        series={series}
        type="bar"
        // width={width || "500"}
        height={height || '500'}
      />
    </div>
  );
};

export default BarChart;
