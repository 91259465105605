import React from 'react';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { ChartProps } from './AreaChart';
import { COLOR } from '../constants';

const RadialChart = (props: ChartProps) => {
  const { data, width, height, colors = [COLOR.clickstream] } = props;
  const display =
    // @ts-ignore
    data.reduce((res, [label, value]) => ({ labels: [...res.labels, label], values: [...res.values, value] }), {
      labels: [],
      values: [],
    });

  const options: ApexOptions = {
    chart: {
      width,
      height,
      type: 'radialBar',
    },
    colors,
    plotOptions: {
      radialBar: {
        dataLabels: {
          name: {
            fontSize: '15px',
            fontWeight: 400,
            fontFamily: 'DM Sans, serif',
          },
          value: {
            fontSize: '22px',
            fontWeight: 500,
          },
          total: {
            show: true,
            color: colors[0],
            label: display.labels[0],
            formatter: function (w) {
              // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
              return `${display.values[0]}%`;
            },
          },
        },
      },
    },
    labels: display.labels,
  };

  const series = display.values;

  return (
    <div className="d-flex align-items-center justify-content-center radial-bar-chart flex-grow-1">
      <Chart options={options} series={series} type="radialBar" width={width || '500'} height={height || '500'} />
    </div>
  );
};
export default RadialChart;
