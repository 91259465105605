import { Amplify, Storage } from 'aws-amplify';

Amplify.configure({
  region: 'ap-southeast-2',
});

export const getAsset = async (assetKey: string) => {
  if (!assetKey) return;
  return await Storage.get(assetKey.substring(1), {
    bucket: 'topproperty-storage-248e8ecf40715-staging',
    level: 'public',
  });
};
