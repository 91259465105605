import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { KTSVG } from '_metronic/helpers';
import { CreateCompanyModal } from '../Modals/CreateCompanyModal';
import { USER_ROLES } from "../../../helpers/utils";

type CompaniesTableHeaderProps = {
  count: number;
  searchTerm: string;
  setSearchTerm: Dispatch<SetStateAction<string>>;
  setSelectedSubscription: Dispatch<SetStateAction<string>>;
  optionsKeys: string[];
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  isLoading: boolean;
  refetch: () => void;
};
const CompaniesTableHeader: FC<CompaniesTableHeaderProps> = ({
  optionsKeys,
  setCurrentPage,
  currentPage,
  count,
  searchTerm,
  setSearchTerm,
  setSelectedSubscription,
  isLoading,
  refetch,
}) => {
  const [companiesCount, setCompaniesCount] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [createCompanyModalOpen, setCreateCompanyModalOpen] = useState<boolean>(false);
  const isSuperAdmin = window.localStorage.getItem('userRole') === USER_ROLES.superadmin;

  useEffect(() => {
    if (!isLoading) setCompaniesCount(count);
  }, [count, isLoading]);

  return (
    <>
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold fs-3 mb-1">All Companies</span>
          <span className="text-muted mt-1 fw-semibold fs-7">Total Count: {companiesCount}</span>
        </h3>
        <div className="row">
          <div className="d-flex col-sm align-items-center">
            <KTSVG path="/media/icons/duotune/general/gen021.svg" className="svg-icon-1 position-absolute ms-6" />
            <input
              type="text"
              data-kt-user-table-filter="search"
              className="form-control form-control-solid w-250px ps-14"
              placeholder="Search company"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>

          {isSuperAdmin && (
              <div className="d-flex col-sm align-items-center">
                <select
                    onChange={(e) => setSelectedSubscription(e.target.value)}
                    defaultValue=""
                    className="form-select form-select-lg cursor-pointer w-200px"
                    aria-label=".form-select-lg example"
                >
                  <option value="">Subscription type</option>
                  {optionsKeys.map((optionsKey) => (
                      <option key={`option-${optionsKey}`} value={optionsKey}>
                        {optionsKey}{' '}
                      </option>
                  ))}
                </select>
              </div>
          )}

          <div className="d-flex col-sm align-items-center">
            <button className="btn btn-dark" onClick={() => setCreateCompanyModalOpen(true)}>
              Create Company
            </button>
          </div>
        </div>

        <div className="card-toolbar">
          <button
            disabled={currentPage === 1}
            onClick={() => setCurrentPage((prevState) => prevState - 1)}
            className="card-label fw-bold fs-3 mb-1 mx-4 border-0"
          >
            -
          </button>
          <span className="card-label fw-bold fs-3 mb-1">
            Page: {currentPage} / {Math.ceil(companiesCount / 50)}
          </span>
          <button
            disabled={50 * currentPage > count}
            onClick={() => setCurrentPage((prevState) => prevState + 1)}
            className="card-label fw-bold fs-3 mb-1  mx-4 border-0"
          >
            +
          </button>
        </div>
      </div>
      {createCompanyModalOpen && (
        <CreateCompanyModal setCreateModalIsOpen={setCreateCompanyModalOpen} refetch={refetch} />
      )}
    </>
  );
};

export { CompaniesTableHeader };
