/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import clsx from 'clsx';
import { getUserByCognitoId } from '../core/_requests';
import {Link, useLocation} from 'react-router-dom';
import { PasswordMeterComponent } from '../../../../_metronic/assets/ts/components';
import { useAuth } from '../core/Auth';
import { Auth } from 'aws-amplify';
import { getAuth } from '../core/AuthHelpers';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';

const registrationSchema = Yup.object().shape({
  password: Yup.string().min(3, 'Minimum 3 symbols').max(50, 'Maximum 50 symbols').required('Password is required'),
  changepassword: Yup.string()
    .required('Password confirmation is required')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
    }),
  acceptTerms: Yup.bool().required('You must accept the terms and conditions'),
});

export default function InvitationForm() {

  const [loading, setLoading] = useState(false);
  const [oldPassword, setOldPassword] = useState('');
  const { saveAuth, setCurrentUser } = useAuth();

  const initialValues = {
    password: '',
    changepassword: '',
    acceptTerms: false,
  };

  const location = useLocation();
  const params = new URLSearchParams(location.search);

  useEffect(() => {

    const email = params.get("e")
    const password = params.get("p")
    setOldPassword(password || '');

    async function handleSignIn(email, password) {
      try {
        const user = await Auth.signIn(email, password);
        const accessToken = (await Auth.currentSession()).getAccessToken().getJwtToken();
        const refreshToken = (await Auth.currentSession()).getRefreshToken().getToken();
        const cognitoID = user?.attributes?.sub;
        const auth = { api_token: accessToken, refreshToken, cognitoID };
        saveAuth(auth);
      } catch (error) {
        console.log('Sign-in failed:', error);
      }
    }
    handleSignIn(email, password);

    // Perform necessary actions with the email and password, such as updating the password
    // ...
  }, []);

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      try {
        Auth.currentAuthenticatedUser()
          .then((user) => {
            return Auth.changePassword(user, oldPassword, values.changepassword);
          })
          .then((data) => console.log(data))
          .catch((err) => console.log(err));
        const auth = getAuth();
        const cognitoID = auth?.cognitoID;
        // @ts-expect-error
        const { data } = await getUserByCognitoId(cognitoID);
        setCurrentUser(data);
      } catch (error) {
        console.error(error);
        saveAuth(undefined);
        setStatus('The registration details is incorrect');
        setSubmitting(false);
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    PasswordMeterComponent.bootstrap();
  }, []);

  return (
    <div className={'h-100 w-100 d-flex justify-content-center align-items-center'}>
      <form
        className="form w-75 w-lg-50 w-xl-25 fv-plugins-bootstrap5 fv-plugins-framework"
        noValidate
        id="kt_login_signup_form"
        onSubmit={formik.handleSubmit}
      >
        {/* begin::Heading */}
        <div className="text-center mb-11">
          {/* begin::Title */}
          <img alt="Logo" src={toAbsoluteUrl('/media/logos/logo-dark-old.png')} className="h-90px" />
          {/* end::Title */}
        </div>
        {/* end::Heading */}

        {formik.status && (
          <div className="mb-lg-15 alert alert-danger">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}

        {/* begin::Form group Password */}
        <div className="fv-row mb-8" data-kt-password-meter="true">
          <div className="mb-1">
            <label className="form-label fw-bolder text-dark fs-6">New Password</label>
            <div className="position-relative mb-3">
              <input
                type="password"
                placeholder="Password"
                autoComplete="off"
                {...formik.getFieldProps('password')}
                className={clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid': formik.touched.password && formik.errors.password,
                  },
                  {
                    'is-valid': formik.touched.password && !formik.errors.password,
                  }
                )}
              />
              {formik.touched.password && formik.errors.password && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{formik.errors.password}</span>
                  </div>
                </div>
              )}
            </div>
            {/* begin::Meter */}
            <div className="d-flex align-items-center mb-3" data-kt-password-meter-control="highlight">
              <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
              <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
              <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
              <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px"></div>
            </div>
            {/* end::Meter */}
          </div>
          <div className="text-muted">Use 8 or more characters with a mix of letters, numbers & symbols.</div>
        </div>
        {/* end::Form group */}

        {/* begin::Form group Confirm password */}
        <div className="fv-row mb-5">
          <label className="form-label fw-bolder text-dark fs-6">Confirm Password</label>
          <input
            type="password"
            placeholder="Password confirmation"
            autoComplete="off"
            {...formik.getFieldProps('changepassword')}
            className={clsx(
              'form-control bg-transparent',
              {
                'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
              },
              {
                'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
              }
            )}
          />
          {formik.touched.changepassword && formik.errors.changepassword && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{formik.errors.changepassword}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className="fv-row mb-8">
          <label className="form-check form-check-inline" htmlFor="kt_login_toc_agree">
            <input
              className="form-check-input"
              type="checkbox"
              id="kt_login_toc_agree"
              {...formik.getFieldProps('acceptTerms')}
            />
            <span>
              I Accept the{' '}
              <a href="https://topproperty.eco/terms-and-conditions" target="_blank" className="ms-1 link-primary">
                Terms
              </a>
              .
            </span>
          </label>
          {formik.touched.acceptTerms && formik.errors.acceptTerms && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{formik.errors.acceptTerms}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}
        {/* begin::Form group Email */}

        {/* end::Form group */}

        {/* begin::Form group */}
        <div className="text-center">
          <button
            type="submit"
            id="kt_sign_up_submit"
            className="btn btn-lg btn-dark w-100 mb-5"
            disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
          >
            {!loading && <span className="indicator-label">Submit</span>}
            {loading && (
              <span className="indicator-progress" style={{ display: 'block' }}>
                Please wait... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
          <Link to="/auth/login" className={'d-flex justify-content-end text-dark'}>
            Cancel
          </Link>
        </div>
        {/* end::Form group */}
      </form>
    </div>
  );
}
