import React, { useState } from 'react';
import * as Yup from 'yup';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import { Auth } from 'aws-amplify';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';

const initialValues = {
  email: '',
  verificationCode: '',
  newPassword: '',
};

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  verificationCode: Yup.string(),
  newPassword: Yup.string().min(8, 'Minimum 8 characters'),
});

export function ForgotPassword() {
  const [loading, setLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined);
  const [emailVerification, setEmailVerification] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [successChange, setSuccessChange] = useState<boolean>(false);

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setHasErrors(undefined);

      try {
        if (values.verificationCode && values.newPassword) {
          await Auth.forgotPasswordSubmit(values.email, values.verificationCode, values.newPassword).catch((err) => {
            console.log(err);
            setHasErrors(false);
          });
          setSuccessMessage('Password reset successful!');
          setEmailVerification(false);
          setSuccessChange(true);
        } else {
          Auth.forgotPassword(values.email)
            .then((data) => console.log(data))
            .catch((err) => {
              console.log(err);
              setHasErrors(true);
            });
          setEmailVerification(true);
          setSuccessMessage('Sent password reset. Please check your email');
        }
        setHasErrors(false);
        setLoading(false);
      } catch (error) {
        setHasErrors(true);
        setLoading(false);
        setSubmitting(false);
        setStatus('The login detail is incorrect');
      }
    },
  });

  return (
    <div className={'h-100 w-100 d-flex justify-content-center align-items-center'}>
      <form
        className="form w-75 w-lg-50 w-xl-25 fv-plugins-bootstrap5 fv-plugins-framework"
        noValidate
        id="kt_login_password_reset_form"
        onSubmit={formik.handleSubmit}
      >
        <div className="text-center mb-10">
          <img alt="Logo" src={toAbsoluteUrl('/media/logos/logo-dark-old.png')} className="h-90px mb-8" />
          <h1 className="text-dark fw-bolder mb-3">Forgot Password?</h1>
          <div className="text-gray-500 fw-semibold fs-6">Enter your email to reset your password.</div>
        </div>

        {hasErrors === true && (
          <div className="mb-lg-15 alert alert-danger">
            <div className="alert-text font-weight-bold text-center">
              Sorry, looks like there are some errors detected, please try again. You may reach over the limit for
              today.
            </div>
          </div>
        )}

        {hasErrors === false && (
          <div className="mb-10 p-8 rounded">
            <div className="text-center text-success">{successMessage}</div>
          </div>
        )}

        <div className="fv-row mb-8">
          <label className="form-label fw-bolder text-gray-900 fs-6">Email</label>
          <input
            type="email"
            placeholder=""
            autoComplete="off"
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control bg-transparent',
              { 'is-invalid': formik.touched.email && formik.errors.email },
              { 'is-valid': formik.touched.email && !formik.errors.email }
            )}
          />
          {formik.touched.email && formik.errors.email && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>

        {emailVerification && (
          <>
            <div className="fv-row mb-8">
              <label className="form-label fw-bolder text-gray-900 fs-6">Verification Code</label>
              <input
                type="text"
                placeholder=""
                autoComplete="off"
                {...formik.getFieldProps('verificationCode')}
                className={clsx(
                  'form-control bg-transparent',
                  { 'is-invalid': formik.touched.verificationCode && formik.errors.verificationCode },
                  { 'is-valid': formik.touched.verificationCode && !formik.errors.verificationCode }
                )}
              />
              {formik.touched.verificationCode && formik.errors.verificationCode && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{formik.errors.verificationCode}</span>
                  </div>
                </div>
              )}
            </div>

            <div className="fv-row mb-8">
              <label className="form-label fw-bolder text-gray-900 fs-6">New Password</label>
              <input
                type="password"
                placeholder=""
                autoComplete="off"
                {...formik.getFieldProps('newPassword')}
                className={clsx(
                  'form-control bg-transparent',
                  { 'is-invalid': formik.touched.newPassword && formik.errors.newPassword },
                  { 'is-valid': formik.touched.newPassword && !formik.errors.newPassword }
                )}
              />
              {formik.touched.newPassword && formik.errors.newPassword && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{formik.errors.newPassword}</span>
                  </div>
                </div>
              )}
            </div>
          </>
        )}

        <div className="d-flex flex-wrap justify-content-center pb-lg-0">
          {!successChange && (
            <>
              <button type="submit" id="kt_password_reset_submit" className="btn btn-dark me-4">
                <span className="indicator-label">Submit</span>
                {loading && (
                  <span className="indicator-progress">
                    Please wait...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
              <Link to="/auth/login">
                <button
                  type="button"
                  id="kt_login_password_reset_form_cancel_button"
                  className="btn btn-light"
                  disabled={formik.isSubmitting || !formik.isValid}
                >
                  Cancel
                </button>
              </Link>
            </>
          )}
          {successChange && (
            <Link to="/auth/login">
              <button
                type="button"
                id="kt_login_password_reset_form_cancel_button"
                className="btn btn-light"
                disabled={formik.isSubmitting || !formik.isValid}
              >
                Back to Login
              </button>
            </Link>
          )}
        </div>
      </form>
    </div>
  );
}
