import React, { Dispatch, FC, SetStateAction, useContext, useEffect, useState } from 'react';
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers';
import { CompanySelectType } from '../../../types/main';
import { getAsset } from '../../../helpers/getAsset';
import { Link } from 'react-router-dom';
import { FileContext } from 'app/modules/accounts/Filecontext';

type CompanyTableElemProps = {
  company: CompanySelectType;
  isModal: boolean;
  closeModal?: Dispatch<SetStateAction<boolean>>;
};

type optionsType = {
  [key: string]: boolean | null;
};

const options: optionsType = {
  Unclaimed: null,
  Subscribed: true,
  Unsubscribed: false,
};

type badgeColorType = {
  [key: string]: string;
};

const badgeColor: badgeColorType = {
  Unclaimed: 'badge-light-danger',
  Subscribed: 'badge-light-success',
  Unsubscribed: 'badge-light-primary',
};

const CompanyTableElem: FC<CompanyTableElemProps> = ({ company, isModal, closeModal }) => {
  const { setSelectedCompany } = useContext(FileContext);
  const [logoUrl, setLogoUrl] = useState<string>();

  const optionsKeys = Object.keys(options);

  const textForDisplay = optionsKeys.find((key) => options[key] === company.subscribed);

  const getLogo = async (logoKey: string) => {
    const logoUrl = await getAsset(logoKey);
    if (typeof logoUrl == 'string') setLogoUrl(logoUrl);
  };

  useEffect(() => {
    getLogo(company.logoLocation);
  }, [company.logoLocation]);

  return (
    <tr>
      <td>
        <div className="symbol symbol-50px me-2">
          <span className="symbol-label">
            <img
              src={logoUrl ? logoUrl : toAbsoluteUrl('/media/svg/brand-logos/plurk.svg')}
              className="h-50 align-self-center"
              alt=""
            />
          </span>
        </div>
      </td>
      <td>
        {isModal ? (
          <div
            onClick={() => {
              localStorage.setItem('companyId', String(company.id));
              setSelectedCompany(company.id);
              if (closeModal) {
                closeModal(false);
              }
            }}
            className="text-dark fw-bold text-hover-primary mb-1 fs-6 cursor-pointer"
          >
            {company.name}
          </div>
        ) : (
          <Link
            onClick={() => {
              localStorage.setItem('companyId', String(company.id));
              setSelectedCompany(company.id);
            }}
            to={'/profile/details'}
            className="text-dark fw-bold text-hover-primary mb-1 fs-6"
          >
            {company.name}
          </Link>
        )}
        <span className="text-muted fw-semibold d-block fs-7">Company ID: {company.id}</span>
      </td>
      <td className="text-end">
        <span
          className={`badge ${textForDisplay ? badgeColor[textForDisplay] : 'badge-light-primary'} fw-semibold me-1`}
        >
          {textForDisplay}
        </span>
      </td>
      <td className="text-end">
        <span className="text-muted fw-semibold">{company.alias}</span>
      </td>
      <td className="text-end">
        <Link
          to={'/profile/details'}
          onClick={() => {
            localStorage.setItem('companyId', String(company.id));
            setSelectedCompany(company.id);
          }}
          className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
        >
          <KTSVG path="/media/icons/duotune/arrows/arr064.svg" className="svg-icon-2" />
        </Link>
      </td>
    </tr>
  );
};

export { CompanyTableElem };
